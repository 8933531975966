import React, { Component } from 'react';
import '../App.css';

export default class ContactUs extends Component {
  render(){
    return (
      <>
        <div className="contact-us new-block pdtb-100">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="block-syl1">
                  <i className="flaticon-facebook-placeholder-for-locate-places-on-maps"></i>
                  <p>Our Address</p>
                  <div className="contact-info">
                    <p>Srirampoure, <br />Hooghly, West Bengal</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="block-syl1">
                  <i className="flaticon-close-envelope"></i>
                  <p>Send Us an Email</p>
                  <div className="contact-info">
                    <p>amoure@support.com <br /> amoure@support.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="block-syl1">
                  <i className="flaticon-old-handphone"></i>
                  <p>Give Us a Ring</p>
                  <div className="contact-info">
                    <p>+01 123 456 789 00 <br />+01 123 456 789 00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="send-note new-block pdtb-100">
          <form>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">Send Us a Note</h2>
                    <div className="clearfix"></div>
                    <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br /> mattis ullamcorper urna. Cras quis elementum</p>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter your first name"/>
                  </div>  
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter your last name"/>
                  </div>  
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter your email"/>
                  </div>  
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter your contact number"/>
                  </div>  
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea className="form-control" placeholder="Enter your message.."></textarea>
                  </div>  
                </div>
                <div className="col-lg-12">
                  <div className="form-group text-center">
                    <div className="c-btn btn1">Send</div>
                  </div>  
                </div>
              </div>
            </div>
          </form>
        </section>

        
      </>
    );
  }
}
