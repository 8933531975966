import React, { Component } from "react";

import axios from "../../utils/axios";
import io from "socket.io-client";
import Chatbox from "./Chatbox";
import "./chatbar.css";
import { Link } from "react-router-dom";
import Auth from "../../Auth";

export default class Chatbar extends Component {
  constructor(props) {
    const chatRef = {};

    super(props);

    this.state = {
      chatlist: [],
      open_chatlist: [],
      user_id: "",
      user_img: "",
      username: "",
      message: "",
    };

    if(Auth.isAuthenticated()) {
      this.fetchChat();
    }

    var _this = this;

    window.openGlobalChat = function (other_user) {
      _this.openChat(other_user);
    };

    this.bindSocket();

    this.childChatRefs = [];

    window.reloadChatlist = function () {
      _this.fetchChat();
    };
  }

  addChildRef = (ref) => {
    if (ref) {
      this.childChatRefs.push(ref);
    }
  }

  passMessageToChild = (dt) => {
    this.childChatRefs.forEach((childRef) => {
      childRef.newMessageTrigger(dt);
    });
  }

  passTypingEventToChild = (dt) => {
    this.childChatRefs.forEach((childRef) => {
      childRef.recieveTyping(dt);
    });
  }

  bindSocket() {
    if(typeof window.userID!="undefined") {
      var _this = this;
      this.props.socket.on("chat_message_"+window.userID, (dt)=> {
        _this.passMessageToChild(dt);
        _this.fetchChat();
      });

      this.props.socket.on("typing_event_"+window.userID, (dt)=> {
        _this.passTypingEventToChild(dt);
      });
    } else {
      var _this = this;
      setTimeout(function() {
        _this.bindSocket();
      }, 500);
    }
  }

  openChat(user_id) {
    var open_chats = this.state.open_chatlist;

    var found_index = -1;

    open_chats.forEach((dt, i) => {
      if (dt == user_id) {
        found_index = i;
      }
    });

    var main_found_index = -1;
    this.state.chatlist.forEach((dts, i) => {
      if (dts.user_id == user_id) {
        main_found_index = i;
      }
    });

    if(main_found_index > -1) {
      var chat_list = this.state.chatlist;
      chat_list[main_found_index].messageunread = 0;
      this.setState({
        chatlist: chat_list
      })
    }

    

    if (found_index < 0) {
      open_chats.push(user_id);

      this.setState({
        open_chatlist: open_chats,
      });
    }
  }

  fetchChat() {
    axios.api_post("get_chat_list", {}, (res) => {
      var data = res.data.data;

      data.forEach((dt, indx)=> {
        if(dt.last_message_read_status==0 && dt.last_message_from_user!=window.userID) {
          dt.messageunread = 1;
        } else {
          dt.messageunread = 0;
        }
      })

      this.setState({
        chatlist: data,
      });
    });
  }

  closeChatWindow(user_id) {
    const open_chats = [...this.state.open_chatlist];

    const indexToRemove = open_chats.indexOf(user_id);

    if (indexToRemove !== -1) {
      open_chats.splice(indexToRemove, 1);
    }

    this.setState({ open_chatlist: open_chats });
  }

  render() {
    return (
      <>
      {(Auth.isAuthenticated() && window.showHeader) &&
        <>
        <div id="chat_holder">
          {
            <>
              {this.state.open_chatlist.map((user_id, idx) => {
                return (
                  <>
                    <Chatbox
                      socket={this.props.socket}
                      closeChatWindow={(id) => this.closeChatWindow(id)}
                      user_id={user_id}
                      ref={this.addChildRef}
                      key={user_id || idx}
                    />
                  </>
                );
              })}
              {/* {Object.keys(this.state.open_chatlist).map((key, i) => {
                const chatData = this.state.open_chatlist[key];
                return (
                  <Chatbox user_id={chatData.user_id} />
                );
              })} */}
            </>
          }
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-8"></div>
          <div className="col-lg-4 col-md-4">
            <div className="online-side-nav">
              <span className="nav-btn">
                <a href="#">
                  <i className="flaticon-left-arrow"></i>
                </a>
              </span>
              <div id="chat-sidebar">
                <h4 className="chatbar-title">Inbox</h4>
                {this.state.chatlist.map((cht, idx) => {
                  return (
                    <div
                      key={cht.user_id}
                      className={'sidebar-user-box '+ ((cht.messageunread==1) && ' unreadSidebarChatBg')}
                      onClick={() => this.openChat(cht.user_id)}
                    >
                      <div className="online-sidebar-image-holder">
                        <img src={cht.profile_image} alt=" " />
                      </div>
                      <i className="flaticon-circular-shape-silhouette"></i>
                      <span className="slider-username">{cht.username}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        </>
      }
      </>
    );
  }
}
