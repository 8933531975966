import React, { Component } from 'react';
import '../App.css';
import { withRouter } from "react-router";
import {
  Carousel,
  Container,
  Row,
  Col
} from 'react-bootstrap';
import Auth from '../Auth';
import axios from '../utils/axios';
var THIS;
class TermsAndCondition extends Component {
constructor(props) {
  super(props);
  this.state = {
    toggle:true
  }
 
}


  render(){
   

    return (
      <>
        
              <div className="contact-us new-block pdtb-100">
                <div className="overlay"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="block-syl-terms">

                        <div className="row headerAlignment" >
                          <h1 className="headerContent text-center">Terms & Conditions</h1>
                        </div>
                        <hr className="headerHrColorStyle" />
                         <div className="row contentHeaderStyle">
                            {this.state.toggle == true ?
                              <div onClick={()=>{this.setState({toggle:!this.state.toggle})}}>
                                <p className="contentStyle"><strong className="contentIconStyle">-</strong> <strong className="content_each_header">What is Lorem Ipsum?</strong></p>
                              </div>
                              :
                              <div onClick={()=>{this.setState({toggle:!this.state.toggle})}}>
                                <p className="contentStyle"><strong className="contentIconStyle">+</strong> <strong className="content_each_header">What is Lorem Ipsum?</strong></p>
                              </div>
                            }
                        </div>
                        <div className="row main_content_style">
                            {
                              this.state.toggle ? <div>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                              </div> : null
                            }
                        </div>


                        <div className="row contentHeaderStyle">
                            {this.state.toggle == true ?
                              <div onClick={()=>{this.setState({toggle:!this.state.toggle})}}>
                                <p className="contentStyle"><strong className="contentIconStyle">-</strong> <strong className="content_each_header">What is Lorem Ipsum?</strong></p>
                              </div>
                              :
                              <div onClick={()=>{this.setState({toggle:!this.state.toggle})}}>
                                <p className="contentStyle"><strong className="contentIconStyle">+</strong> <strong className="content_each_header">What is Lorem Ipsum?</strong></p>
                              </div>
                            }
                        </div>
                        <div className="row main_content_style">
                            {
                              this.state.toggle ? <div>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                              </div> : null
                            }
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            
      </>
    );
  }
}


export default withRouter(TermsAndCondition);