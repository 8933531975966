import React, { Component } from 'react';
import '../App.css';
import { withRouter } from "react-router";

import Auth from '../Auth';
import UserCard from '../Components/UserCard';

import axios from '../utils/axios';
import PageLoader from '../utils/PageLoader';
import AmoureLoader from '../Components/AmoureLoader';

class Home extends Component {

	constructor(props) {

	    super(props);

	    if(Auth.isAuthenticated()) {
	    	this.props.history.replace('/home');
	    }

	    this.state = {
			activetab: 'online',
			top_users: [],
			online_users: [],
			fetched_users: [],
			singles: [],
			searchVal: '',
			isLoading: false,
			refreshing: false,
			isLoadingmore: false,
			options: {
				all: true,
				online: false,
				new: false,
				visitors: false,
				singles: false,
				married: false,
				divorced: false
			}
	    }

	    // getting users initially
	    this.getUsers();

	    this.handleScroll = this.handleScroll.bind(this);

	    this.pageNumber = 0;

	}

	handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        const windowBottom = Math.round(windowHeight + window.pageYOffset);

        if (windowBottom >= docHeight) {
        	this.getUsers(true);
        }
    }

	componentDidMount() {
	  document.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
	  document.removeEventListener('scroll', this.handleScroll);
	}

	setOptionActiveToggle(opt) {

		// alert(opt);

	    var opts = this.state.options;

	    switch(opt) {

	      case 'all': 
	        opts.all = true;
	        opts.online = false;
	        opts.new = false;
	        opts.visitors = false;
	        opts.singles = false;
	        opts.married = false;
	        opts.divorced = false;
	        break;

	      case 'online':
	        opts.online = !opts.online;
	        break;

	      case 'new':
	        opts.new = !opts.new;
	        break;

	      case 'visitors':
	        opts.visitors = !opts.visitors;
	        break;

	      case 'singles':
	        opts.singles = !opts.singles;
	        opts.divorced = false;
	        opts.married = false;
	        break;

	      case 'married':
	        opts.singles = false;
	        opts.married = !opts.married;
	        opts.divorced = false;
	        break;

	      case 'divorced':
	        opts.singles = false;
	        opts.married = false;
	        opts.divorced = !opts.divorced;
	        break;

	    }

	    if(!opts.online && !opts.new && !opts.visitors && !opts.singles && !opts.married && !opts.divorced) {
	      opts.all = true;
	    } else {
	      opts.all = false;
	    }

	    // console.log(opts);

	    this.setState({
	      options: opts
	    });

	    // global.homeFilterOptions = opts;

	    // AsyncStorage.setItem('homeFilterOptions', JSON.stringify(opts));

	    var _THIS = this;

	    if(this.fetchInterval) {
	      clearTimeout(this.fetchInterval);
	    }

	    this.fetchInterval = setTimeout(function() {
	      _THIS.getUsers();
	    }, 100);

	}

	getUsers(loadmore) {

		if(loadmore) {
			this.pageNumber =this.pageNumber + 1;
			this.setState({
				isLoadingmore: true
			});
		} else {

			this.setState({
				isLoading: true
			});

			// window.show_page_loader(true);
			this.pageNumber = 0;
		}

		axios.api_post('discover_users', {
			search_username: this.state.searchVal,
			options: JSON.stringify(this.state.options),
			page_num: this.pageNumber
	    }, res => {

	    	var users = [];

	    	if(loadmore) {
	    		users = this.state.fetched_users;

	    		res.data.data.fetched_users.map((item, key) => {
	    			users.push(item);
	    		});

	    	} else {
				// window.show_page_loader(false);
	    		users = res.data.data.fetched_users;
	    	}

	    	this.setState({
				isLoading: false,
				isLoadingmore: false,
	    		fetched_users: users
	    	})

	    });

	}

	render(){
    return (
    	<>
		{this.state.isLoading &&
			<AmoureLoader></AmoureLoader>
		}

		<div className="search-form new-block" id='search-form-block'>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="inner-wrapper">
							<form>
								<div className="row tabs_container">
									<div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.all ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('all')}>
										<label className="main_content_search">All</label>
									</div>
									{/* <div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.online ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('online')}>
										<label className="main_content_search">Online</label>
									</div> */}
									<div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.new ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('new')}>
										<label className="main_content_search">New</label>
									</div>
									<div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.married ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('married')}>
										<label className="main_content_search">Married</label>
									</div>
									<div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.singles ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('singles')}>
										<label className="main_content_search">Single</label>
									</div>
									<div className={'col-lg-2 col-md-2 col-sm-2 search_content_alignment ' + (this.state.options.divorced ? 'active' : '')} onClick={()=> this.setOptionActiveToggle('divorced')}>
										<label className="main_content_search">Divorced</label>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		{this.state.isLoadingmore &&
			<div id='home-bottom-loader' className='d-flex justify-content-center align-items-center'>
				<i className="fa fa-spinner fa-spin fa-2x" style={{color: '#FFF'}}></i>
			</div>
		}
		<section className="lamour-suggest new-block content_align">
            <div className="container home-loader-container">
          		<div className="row">
					{this.state.fetched_users.map(cht => (
						<UserCard user_data={cht} />
						))
					}
          		</div>
            </div>
    	</section>
		</>
    );
  }
}


export default withRouter(Home);