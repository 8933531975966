import React, { Component } from 'react';
import '../App.css';
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'  
import {
  Carousel,
  Container,
  Row,
  Col
} from 'react-bootstrap';
import Auth from '../Auth';
import axios from '../utils/axios';


class Pricing extends Component {

  constructor(props) {
    super(props);
    // this.selectorPricingTemplate();


  }

  render(){
    return (
      <>
        <section className="pricing py-5">
          <div className="container pricingAlignment">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Selected</h5>
                    <h6 className="card-price text-center"><i className="fa fa-inr"></i>1500<span className="period">/<strong>6</strong> month</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Single User</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>5GB Storage</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Public Projects</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Community Access</li>
                      <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Unlimited Private Projects</li>
                      <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Dedicated Phone Support</li>
                      <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Free Subdomain</li>
                      <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Monthly Status Reports</li>
                    </ul>
                    <a href="#" className="btn btn-block btn-primary text-uppercase">PAY</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Popular</h5>
                    <h6 className="card-price text-center"><i className="fa fa-inr"></i>900<span className="period">/<strong>3</strong> month</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-check"></i></span><strong>5 Users</strong></li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>50GB Storage</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Public Projects</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Community Access</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Private Projects</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Dedicated Phone Support</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Free Subdomain</li>
                      <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Monthly Status Reports</li>
                    </ul>
                    <a href="#" className="btn btn-block btn-primary text-uppercase">PAY</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Selected</h5>
                    <h6 className="card-price text-center"><i className="fa fa-inr"></i>200<span className="period">/<strong>1</strong> month</span></h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-check"></i></span><strong>Unlimited Users</strong></li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>150GB Storage</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Public Projects</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Community Access</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Private Projects</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Dedicated Phone Support</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span><strong>Unlimited</strong> Free Subdomains</li>
                      <li><span className="fa-li"><i className="fa fa-check"></i></span>Monthly Status Reports</li>
                    </ul>
                    <a href="#" className="btn btn-block btn-primary text-uppercase">PAY</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}


export default withRouter(Pricing);