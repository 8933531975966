import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Viewer from 'react-viewer';

var THIS;
class Details extends Component {
  constructor(props) {
    super(props);

    const { data_details } = props;

    this.state = {
      showModal: false,
      image_url: "",
      favourite: data_details.favourite,
      crush: data_details.crush,
    };

    THIS = this;
  }

  openChat() {
    window.openGlobalChat(this.props.data_details.id);
  }

  toggleFavourite() {
    axios.api_post(
      "send_activity",
      {
        to_user: this.props.data_details.id,
        type: "favourite",
        extra_data: "",
      },
      (rs) => {
        this.setState({
          favourite: !this.state.favourite,
        });
      }
    );
  }

  toggleCrush() {
    axios.api_post(
      "send_activity",
      {
        to_user: this.props.data_details.id,
        type: "crush",
        extra_data: "",
      },
      (rs) => {
        this.setState({
          crush: !this.state.crush,
        });
      }
    );
  }

  handleKeyPress = (event) => {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      console.log("Escape key pressed!");
    }
  };

  render() {
    var gender = "";
    console.log(this.props.data_details);
    // console.log('hello', this.props.data_details)
    if (this.props.data_details.gender == "M") {
      gender = "Male";
    } else if (this.props.data_details.gender == "F") {
      gender = "Female";
    }

    return (
      <>
        <Viewer
          visible={this.state.showModal}
          images={[{src: this.state.image_url}]}
          drag={false}
          scalable={false}
          downloadable={false}
          noNavbar={true}
          noFooter={true}
          noToolbar={true}
          onMaskClick={()=> {
            this.setState({
              showModal: false
            })
          }}
          onClose={()=> {
            this.setState({
              showModal: false
            })
          }}
        />
        <section
          className="lamour-suggest new-block content_align"
          onKeyDown={(e) => this.handleKeyPress(e)}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 col-sm-12 text-center">
                <div
                  className="row text-center"
                  style={{ paddingLeft: "15px", paddingRight: "15px" }}
                >
                  {this.props.data_details.online == true ? (
                    <div className="detailsHeaderStyleOnline"></div>
                  ) : (
                    <div className="detailsHeaderStyleaway"></div>
                  )}
                  <p className="headerContentStyle">
                    <strong className="headerStyle">
                      {this.props.data_details.username}
                    </strong>
                    <strong className="headerStyleSub">Last Activity :</strong>
                    <strong className="headerStyleSub">
                      {this.props.data_details.seen_time_ago}
                    </strong>
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-xl-4 col-md-4 col-xs-4 col-sm-4 text-center">
                    <div
                      className="row text-center "
                      style={{ padding: "15px" }}
                    >
                      <section className="lamour-suggest new-block">
                        <div className="container">
                          <div className="row">
                            <div className="block-stl12">
                              <div className="img-holder">
                                <img
                                  src={this.props.data_details.profile_image}
                                  alt=""
                                  className="img-responsive"
                                />
                              </div>
                              <div className="txt-block">
                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-comments iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Start chat session
                                  </h3>
                                </div>

                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-gift iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Send Gift
                                  </h3>
                                </div>

                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-heart iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Send crush alert
                                  </h3>
                                </div>

                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-star iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Add to favourite
                                  </h3>
                                </div>

                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-ban iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Block this profile
                                  </h3>
                                </div>

                                <div className="row profile_details_data">
                                  <h3>
                                    <i
                                      className="fa fa-flag iconStyle"
                                      aria-hidden="true"
                                    ></i>
                                  </h3>
                                  <h3 className="detailsDataStyle">
                                    Report abusive user
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="col-lg-8 col-xl-8 col-md-8 col-xs-8 col-sm-8 text-center">
                    <div className="row" style={{ paddingTop: "15px" }}>
                      <div className="row" style={{ width: "100%" }}>
                        <section className="lamour-suggest new-block">
                          <div className="container">
                            <div className="block-stl2 setContentAlignment">
                              <div className="txt-block">
                                <div className="row">
                                  <h1 className="personalHeader">
                                    <i className="fa fa-female iconStyle"></i>
                                    <strong className="personalInfoHeaderContent">
                                      PERSONAL INFORMATION
                                    </strong>
                                  </h1>
                                </div>

                                <div className="row">
                                  <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                    <div className="row personalContentAlignment">
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyle">City :</p>
                                      </div>
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyleContent">
                                          {this.props.data_details.city}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row personalContentAlignment">
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyle">State :</p>
                                      </div>
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyleContent">
                                          {this.props.data_details.state}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row personalContentAlignment">
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyle">
                                          Country :
                                        </p>
                                      </div>
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyleContent">
                                          {this.props.data_details.country}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row personalContentAlignment">
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyle">Sex :</p>
                                      </div>
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyleContent">
                                          {gender}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row personalContentAlignment">
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyle">Age :</p>
                                      </div>
                                      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                        <p className="contentStyleContent">
                                          {this.props.data_details.age} Years
                                          old
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                                    <div id="gallery row clearfix">
                                      {this.props.data_details.images &&
                                        this.props.data_details.images.map(
                                          (img, index) => {
                                            return (
                                              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                <div className="gallery-item">
                                                  <img
                                                  src={img.url}
                                                  alt=""
                                                  
                                                  onClick={() => {
                                                    this.setState({
                                                      showModal: true,
                                                      image_url: `${img.url}`
                                                    });
                                                  }}
                                                />
                                                  </div>
                                                
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>

                    <div className="row" style={{ paddingTop: "15px" }}>
                      <div className="row" style={{ width: "100%" }}>
                        <section className="lamour-suggest new-block">
                          <div className="container">
                            <div className="block-stl2 setContentAlignment">
                              <div className="txt-block">
                                <div className="row">
                                  <h1 className="personalHeader">
                                    <i className="fa fa-user iconStyle"></i>
                                    <strong className="personalInfoHeaderContent">
                                      PROFILE INFORMATION
                                    </strong>
                                  </h1>
                                </div>

                                <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                                  <div className="row">
                                    <div
                                      className="row personalContentAlignment"
                                      style={{
                                        marginRight: "50%",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {console.log(this.props.data_details.profile_fields)}
                                      {this.props.data_details.profile_fields &&
                                        typeof this.props.data_details
                                          .profile_fields != "undefined" &&
                                        this.props.data_details.profile_fields.map(
                                          (fitem) => {
                                            // console.log('fitem', fitem);
                                            if (fitem.general_details == 1) {
                                              return (
                                                <>
                                                  <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                                    <p className="contentStyle">
                                                      {fitem.field_name} :
                                                    </p>
                                                  </div>
                                                  {fitem.options.map(
                                                    (opt_item) => {
                                                      return (
                                                        <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-xs-6">
                                                          <p className="contentStyleContent">
                                                            {
                                                              opt_item.option_name
                                                            }
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                                  <div className="row">
                                    <div
                                      className="row personalContentAlignment"
                                      
                                    >
                                      {console.log(this.props.data_details.profile_fields)}
                                      {this.props.data_details.profile_fields &&
                                        typeof this.props.data_details
                                          .profile_fields != "undefined" &&
                                        this.props.data_details.profile_fields.map(
                                          (fitem) => {
                                            // console.log('fitem', fitem);
                                            if (fitem.general_details == 0) {
                                              return (
                                                <>
                                                  <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12">
                                                    <p className="contentStyle profileContentStyleSpace">
                                                      {fitem.field_name} :
                                                    </p>
                                                    {fitem.options.map(
                                                    (opt_item) => {
                                                      return (
                                                        <span className="contentStyleContentTabs">
                                                            {
                                                              opt_item.option_name
                                                            }
                                                          </span>
                                                      );
                                                    }
                                                  )}
                                                  </div>
                                                  
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profile_bottom_button_holder">
            <div className="profile_bottom_buttons_inner_container">
              <div
                className="profileInteractionButtonBottom"
                onClick={() => this.toggleFavourite()}
              >
                {this.state.favourite == true ? (
                  <i className="fa fa-star" style={{ color: "orange" }}></i>
                ) : (
                  <i className="fa fa-star-o" style={{ color: "orange" }}></i>
                )}
              </div>
              <div
                className="profileInteractionButtonBottom"
                onClick={() => this.toggleCrush()}
              >
                {this.state.crush == true ? (
                  <i className="fa fa-heart" style={{ color: "red" }}></i>
                ) : (
                  <i className="fa fa-heart-o" style={{ color: "red" }}></i>
                )}
              </div>
              <div
                className="profileInteractionButtonBottom profileInteractionButtonBottomChat"
                onClick={() => this.openChat()}
              >
                <i className="fa fa-comment"></i>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Details);
