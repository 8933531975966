import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";

import Auth from "../Auth";
import DetailsNew from "../Components/DetailsNew";

import axios from "../utils/axios";

import ReportModal from "../Components/ReportModal";
import AmoureLoader from "../Components/AmoureLoader";
import Alert from "../utils/Alert";
class AllDetails extends Component {
  // const { id } = match.params;
  constructor(props) {
    super(props);
    // const { state } = this.props.history.location;
    this.state = {
      id: atob(this.props.match.params.id),
      // id : state.id,
      // dataKey : state.dataKey,
      dataSet: null,
      isRepotShow: false,
      reason: "",
      message: "",
    };
    this.getDetails();
    this.userBlock = this.userBlock.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitReport = this.submitReport.bind(this);
  }

  getDetails() {
    var id = this.state.id;
    window.show_page_loader(true);
    axios.api_post("get_user_details/" + id, {}, (res) => {
      window.show_page_loader(false);
      if (res.data.status == true) {
        this.setState({
          dataSet: res.data.data,
        });
      }
    });
  }

  userBlock(type) {
    var id = this.state.id;
    axios.api_post(
      "block_toggle_user/" + id,
      {
        type: type,
        blocked_user_id: id,
      },
      (res) => {
        console.log("12", res.data.data);

        if (res.data.status === true) {
          this.getDetails();
        }
      }
    );
  }

  openReportModal() {
    const modalStatus = this.state.isRepotShow;
    this.setState({
      isRepotShow: !modalStatus,
    });
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  }
  //  endpoint = report_profile_options /to_user,reason,message
  submitReport() {
    if (!this.state.reason) {
      Alert.showMessage("Report", "Please Enter cause", "error", "OK");
    } else if (!this.state.message) {
      Alert.showMessage("Report", "Please Enter message", "error", "OK");
    } else {
      var id = this.state.id;
      axios.api_post(
        "report_profile_options/" + id,
        {
          to_user: id,
          reason: this.state.reason,
          message: this.state.message,
        },
        (res) => {
          if (res.data.status === true) {
            this.openReportModal();
            Alert.showMessage("Report", res?.data?.message, "success", "OK");
          }
        }
      );
    }
  }

  render() {
    return (
      <>
        {this.state.dataSet != null ? (
          <div className="container">
            <DetailsNew
              data_details={this.state.dataSet}
              userBlock={this.userBlock}
              openReportModal={this.openReportModal}
            />
          </div>
        ) : (
          <div className="vh-100">
            <AmoureLoader></AmoureLoader>
          </div>
        )}
        <ReportModal
          isRepotShow={this.state.isRepotShow}
          openReportModal={this.openReportModal}
          handleChange={this.handleChange}
          reason={this.state.reason}
          message={this.state.message}
          submitReport={this.submitReport}
        />
      </>
    );
  }
}

export default withRouter(AllDetails);
