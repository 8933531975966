import swal from "sweetalert";
class AlertMessage {
  constructor() {}

  showMessage(title, message, type, buttonName) {
    swal({
      title: title,
      text: message,
      icon: type,
      button: buttonName,
    });
  }
}

const Alert = new AlertMessage();

export default Alert;
