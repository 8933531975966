
import axios from './utils/axios';

class Authentication {

  constructor() {
    /*this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: '<YOUR_AUTH0_DOMAIN>',
      audience: 'https://<YOUR_AUTH0_DOMAIN>/userinfo',
      clientID: '<YOUR_AUTH0_CLIENT_ID>',
      redirectUri: 'http://localhost:3000/callback',
      responseType: 'id_token',
      scope: 'openid profile'
    });*/

    this.getProfile = this.getProfile.bind(this);
    this.checkAuthenticated = this.checkAuthenticated.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signout = this.signout.bind(this);
    this.idToken = '';
    this.profile={};
    this.tokenValidated = false;

    if(localStorage.getItem('token')) {
    	this.idToken = localStorage.getItem('token');
    	this.tokenValidated = true;

      this.fetchProfile();
    }

  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return this.tokenValidated;
  }

  signIn(username, password, cb) {

    axios.api_post('login_user/', {
      phone: username,
      password: password
  	}, res => {

    	if(res.data.status) {
    		localStorage.setItem('token', res.data.token);
    		this.tokenValidated=true;
    		cb(true);
    	} else {
    		cb(false);
    	}

	  });

  }

  fetchProfile() {

    axios.api_post('get_currentuser_profile', {}, res => {

      if(res.data.status) {
        this.profile = res.data.data;
        window.userID = res.data.data.id;
      }

    });

  }

  validateUserToken(token, cb){

    axios.api_post('validate_user_token/', {
        token: token
    }, res => {

      cb(res);

    });

  }

  socialLoginCall(id, email, provider, token, cb){

    axios.api_post('social_login_signup/', {
        login_provider: provider,
        account_id: id,
        email: email,
        token: token
    }, res => {

      cb(res);

    });

  }

  registration(username, email_phone, password, city, state, age, gender, lookingfor, cb){
    axios.api_post('register_user/', {
        username: username,
        email_phone: email_phone,
        password: password,
        city: city,
        state: state,
        age: age,
        gender: gender,
        lookingfor: lookingfor,
    }, res => {
      // console.log('res', res);
      if(res.data.status) {
        localStorage.setItem('token', res.data.token);
        this.tokenValidated=true;
        cb(true);
      } else {
        cb(false);
      }

    });
  }

  checkAuthenticated() {

    return new Promise((resolve, reject) => {
    	// login_user
      if(localStorage.getItem('token')) {
        this.idToken = localStorage.getItem('token');
        this.tokenValidated = true;
      }
		  resolve();
    });

  }

  signout() {

  	localStorage.removeItem('token');

    // clear id token, profile, and expiration
    this.tokenValidated = false;
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;

  }

}

const Auth = new Authentication();

export default Auth;