import React, { Component } from "react";

import axios from "../../utils/axios";
import Socket from "../../utils/socket";
import Alert from "../../utils/Alert";
import Moment from 'react-moment';
import moment from 'moment';
import { Modal } from "react-bootstrap";

import Viewer from 'react-viewer';

import $ from 'jquery';

import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';

export default class Chatbox extends Component {

    constructor(props) {

      super(props);
      
      this.sendMessage = this.sendMessage.bind(this);
      this.fetchChatData = this.fetchChatData.bind(this);

      this.state = {
        user_id: "",
        showAudioModal: false,
        refKey: "",
        other_user_typing: false,
        image_url: "",
        audio_url: "",
        last_seen_text: "",
        last_seen_tmp: "",
        message_text: "",
        profile_image: "",
        imageModalShow: false,
        mediaCreditText: "Loading...",
        username: "",
        messages: [],
      };

      this.fetchLastSeenInterval = setInterval(() => {
        this.fetchLastSeen();
      }, 40000);

      this.messagesRef = React.createRef();
      this.messageInputRef = React.createRef();
      this.imageInputRef = React.createRef();

      this.fetchChatData();

      this.bindSocket();
      this.fetchMediaCredit();

    }

    sendTypingEvent(event) {
      var dt = {
        event: event,
        from_user: window.userID,
        to_user: this.props.user_id
      }

      this.props.socket.emit("typing_event_v2", JSON.stringify(dt));
    }

    newMessageTrigger(dt) {
      if(dt.data.from_user==this.props.user_id) {
        var messages = this.state.messages;
        var dt = this.buildMessage(dt.data);
        messages.push(dt);
        
        this.setState({
          messages: messages
        });
  
        this.scrollToBottom();
      }
    }

    bindSocket() {
      // if(typeof window.userID!="undefined") {
      //   this.props.socket.on("typing_event_"+window.userID, (dt)=> {
      //     console.log("typing");
          
      //     // other_user_typing
      //   });
      // } else {
      //   var _this = this;
      //   setTimeout(function() {
      //     _this.bindSocket();
      //   }, 200);
      // }
      
      // this.props.socket.on("chat_message_"+window.userID, (dt)=> {
        
      // });
    }

    scrollToBottom = () => {
      window.scrollToBottom($(this.messagesRef.current));
    }

    recieveTyping(dt) {
      if(dt.from_user==this.props.user_id) {
        var other_user_typing = false;
        var last_seen_text = this.state.last_seen_tmp;
        var txt = "";

        if(dt.event=="typing") {
          txt = "typing...";
          other_user_typing = true;
        } else if(dt.event=="typeend") {
          txt = last_seen_text;
          other_user_typing = false;
        }

        this.setState({
          other_user_typing: other_user_typing,
          last_seen_text: txt
        });
        
      }
    }

    fetchMediaCredit() {
      axios.api_post("get_user_media_credit_remaining", {}, (res)=> {
        if(res.data.status) {
          this.setState({
            mediaCreditText: res.data.credit
          });
        }
      });
    }

    componentWillUnmount() {
      this.unbindAll();
      
    }

    unbindAll() {
      // this.props.socket.off("typing_event_"+window.userID);
      // this.props.socket.off("chat_message_"+window.userID);
      clearInterval(this.fetchLastSeenInterval);
    }

    fetchChatData() {

        axios.api_post(
          "get_user_messages",
          {
            to_user: this.props.user_id,
          },
          (res) => {

            if (res.data.status) {
                var dtas = [];
    
                res.data.data.forEach((item) => {

                    var dt = this.buildMessage(item);

                    dtas.push(dt);

                });

                this.setState({
                    messages: dtas,
                    username: res.data.user_data.username,
                    last_seen_text: "loading...",
                    profile_image: res.data.user_data.profile_image
                });

                this.scrollToBottom();

                this.fetchLastSeen();
                
            }
          }
        );
    }

    buildMessage(data) {

      const utcTime = moment.utc(data.created_on_org).local();

      data.localTimeString = utcTime;

      data.self = false;
      var type="text";

      if(data.image!="" && data.image!=null) {
        type="image";
      } else if(data.audio_path!="" && data.audio_path!=null) {
        type="audio";
      }

      data.type = type;

      if (data.from_user == window.userID) {
        data.self = true;
      }

      return data;

    }

    fetchLastSeen() {
      if(!this.state.other_user_typing) {
        axios.api_post(
          "get_user_last_seen",
          {
            other_user_id: this.props.user_id,
          },
          (rs) => {
    
            this.setState({
                last_seen_text: rs.data.online ? "Online" : rs.data.last_seen,
                last_seen_tmp: rs.data.online ? "Online" : rs.data.last_seen,
            });

          }
        );
      }

    }

    handleInput(val) {
      if(val.length > 0) {
        this.sendTypingEvent("typing");
      } else {
        this.sendTypingEvent("typeend");
      }

      this.setState({
        message_text: val,
      });
    }

    submitInputMessage() {
      var message = this.state.message_text;
      if(message.length > 0) {
        this.sendMessage(message, "text");
      }
      this.setState({
        message_text: ''
      })
    }

    sendMessage(msg_str, type) {

      this.sendTypingEvent("typeend");

      axios.api_post('send_chat_message_creditbased', {
        to_user: this.props.user_id,
        type: type,
        replied_by_bothside: "0",
        chat_master_id: "0",
        message: msg_str
      }, res => {
        if(res.data.status) {

          window.reloadChatlist();

          var messages = this.state.messages;
          var dt = this.buildMessage(res.data.data);

          messages.push(dt);

          this.setState({
            messages: messages
          });

          this.scrollToBottom();

          if(res.data.credit_deducted) {
            window.reloadCredits();
          }

        } else if(res.data.reason=="no_membership") {
          window.show_no_credit_popup(res.data.msg);
        } else {
          Alert.showMessage("OOPS", res.data.msg, "error", "OK");
        }
        
      });

    }

    handleInputTextKeyPress = (event) => {
      if(event.key === 'Enter'){
        this.submitInputMessage();
      }
    }

    handleInputTextKeyDown = (event) => {
      if (event.keyCode === 27) {
        this.closeBox();
      }
    }

    closeBox() {
      this.unbindAll();
      this.props.closeChatWindow(this.props.user_id);
    }

    componentDidMount() {
      this.messageInputRef.current.focus();
    }

    componentWillUnmount() {
      this.sendTypingEvent("typeend");
    }

    getDate(dateString) {
      var dateTime = moment(dateString).format('YYYY-MM-DD');
      return dateTime;
    }

    getTopDate(dt, index) {
      var str = "";
      var dateTime = moment(dt.localTimeString);
      var sameAsPrevious = true;
      if((index-1) > -1) {
        var previousDate = this.getDate(this.state.messages[(index-1)].localTimeString);
        var todaysDate = this.getDate(dt.localTimeString);
        if(previousDate!=todaysDate) {
          sameAsPrevious = false;
        }
      } else {
        sameAsPrevious = false;
      }

      if(!sameAsPrevious) {
        // calculate the start of today and yesterday
        const startOfToday = moment().startOf('day');
        const startOfYesterday = moment().subtract(1, 'days').startOf('day');

        if (dateTime.isSame(startOfToday, 'd')) {
          str = 'Today';
        } else if (dateTime.isSame(startOfYesterday, 'd')) {
          str = 'Yesterday';
        } else {
          str = dateTime.format('MMM D, YYYY'); //show other dates in format "Jan 19, 2022"
        }

        return (
          str
        );
      }
      
    }

    viewImage(message) {
      this.check_media_viewing_elegibility(message, (st)=> {
        this.setState({
          imageModalShow: true,
          image_url: "https://amourechatfiles.s3.us-east-2.amazonaws.com/" + message.image
        });
      });
    }

    viewAudio(message) {
      this.check_media_viewing_elegibility(message, (st)=> {
        // message
        this.setState({
          showAudioModal: true,
          audio_url: "https://amourechatfiles.s3.us-east-2.amazonaws.com/" + message.audio_path
        });
      });
    }

    check_media_viewing_elegibility(message, cb) {
      axios.api_post("check_media_viewing_elegibility", {
        message_id: message.id
      }, (rs)=> {
        if(rs.data.status=="allow") {
          cb(true);
        } else if(rs.data.status=="lowcredit") {
          cb(false);
          global.show_no_credit_popup(rs.data.message);
        } else if(rs.data.status=="expired") {
          cb(false);
          Alert.showMessage("OOPS", rs.data.message, "error", "OK");
        }
      });
    }

    async uploadFile(file) {

      const formData = new FormData();
      formData.append('file', file);
  
      axios({
        method: 'post',
        url: axios.getBaseUrl()+"upload_user_chat_files",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer '+localStorage.getItem('token'),
        }
      }).then(response => {
        if(response.data.status) {
          this.sendMessage(response.data.data.filepath, "image");
        }
      });

    }

    onFileChange = event => {
      this.uploadFile(event.target.files[0]);
    };

    naviagteToProfile () {
      const { history } = this.props;
      var user_id = btoa(this.props.user_id);
      // history.push(`/profile_details/${user_id}`);
      window.location.href=`/profile_details/${user_id}`;
    }

    openImageChooser() {
      this.imageInputRef.current.click();
    }

    centerOpenWindow(url, width, height) {

      const x = window.innerWidth / 2 - width / 2;
      const y = window.innerHeight / 2 - height / 2;
  
      var newWindow = window.open(url, 'Media Credit Info', `width=${width},height=${height},left=${x},top=${y}`);
  
    }

    openCreditInfoPage() {
      this.centerOpenWindow("https://amoure.in/media_credit_charges_info_amoure.html" ,500,500);
    }

    render() {
        return (
            <>
              <Viewer
                visible={this.state.imageModalShow}
                images={[{src: this.state.image_url}]}
                drag={false}
                scalable={false}
                downloadable={false}
                noNavbar={true}
                noFooter={true}
                noToolbar={true}
                onMaskClick={()=> {
                  this.setState({
                    imageModalShow: false
                  })
                }}
                onClose={()=> {
                  this.setState({
                    imageModalShow: false
                  })
                }}
              />

              <Modal show={this.state.showAudioModal} onHide={()=> this.setState({
                showAudioModal: false
              })}>
                <Modal.Body>
                  <AudioPlayer
                    autoPlay
                    src={this.state.audio_url}
                    onPlay={(e) => console.log("onPlay")}
                  />
                </Modal.Body>
              </Modal>
              
              <div className="msg_box" onKeyDown={this.handleInputTextKeyDown}>
                <input type="file" style={{ display: "none" }} onChange={this.onFileChange} ref={this.imageInputRef} />
                <div className="msg_head">
                  <div className="chat-image-holder" onClick={()=> this.naviagteToProfile()}>
                    <img src={this.state.profile_image} />
                  </div>
                  <div className="u_name" onClick={()=> this.naviagteToProfile()}>
                    <span>{this.state.username}</span>
                    <small>{this.state.last_seen_text}</small>
                  </div>
                  <div
                    className="close-white close-chatbox"
                    onClick={() => this.closeBox()}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                </div>
                <div className="msg_info_header d-flex align-items-center">
                  <span>
                    Note:- Be respectful
                  </span>
                  <span className="mediaCreditButton" onClick={()=> this.openCreditInfoPage()}>
                    <i className="fa fa-info-circle"></i>
                    Media Credit: {this.state.mediaCreditText}
                  </span>
                </div>
                <div className="msg_body" ref={this.messagesRef}>
                  <div id="msg_push" className="msg_push"
                    
                  >
                    {this.state.messages.map((dt, i) => {
                      return (
                        <>
                          <div className="msg-top-date">{this.getTopDate(dt, i)}</div>
                          <div className={`msg-item ${dt.self ? 'msg-right' : 'msg-left'}`}>
                            {dt.type=="text" &&
                              (
                                dt.message
                              )
                            }

                            {dt.type=="image" &&
                              <span className={`btn btn-chat-media ${dt.self ? 'btn-msg-right' : 'btn-msg-left'}`} onClick={()=> this.viewImage(dt)}>
                                <i className="fa fa-image"></i> 
                                View Image</span>
                            }

                            {dt.type=="audio" &&
                              <span className={`btn btn-chat-media ${dt.self ? 'btn-msg-right' : 'btn-msg-left'}`} onClick={()=> this.viewAudio(dt)}><i className="fa fa-music"></i> Play Audio</span>
                            }

                            <div className="timestamp-container" style={{textAlign: 'right'}}>
                              <Moment element="span" format="hh:mm a" date={dt.localTimeString} className="chat-timestamp" />
                            </div>
                            
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="msg_footer text-block">
                  <span
                    className="send-msg-btn"
                    onClick={(e) => this.openImageChooser()}
                  >
                    <i className="fa fa-image"></i>
                  </span>
                  <input
                    ref={this.messageInputRef}
                    type="text"
                    name="message_text"
                    placeholder="Type and hit enter"
                    className="msg_input form-control"
                    onChange={(e) => this.handleInput(e.target.value)}
                    onKeyPress={this.handleInputTextKeyPress}
                    onKeyDown={this.handleInputTextKeyDown}
                  />
                  <span
                    className="send-msg-btn"
                    onClick={() => this.submitInputMessage()}
                  >
                    <i className="fa fa-paper-plane-o"></i>
                  </span>
                </div>
              </div>
            </>
          )
    }

}