import React from "react";
import { Modal } from "react-bootstrap";

const ReportModal = ({
  isRepotShow,
  openReportModal,
  handleChange,
  reason,
  message,
  submitReport,
}) => {
  return (
    <>
      <Modal show={isRepotShow} onHide={() => openReportModal()}>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-center align-items-center w-100">
            Report User Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="reportSubtitle">
            Kindly let us know the reason you are reporting this profile. Our
            moderation team will look into this within 24 hours
          </span>
          <div className="form-group">
            <label htmlFor="reason" className="d-flex">
              Select Cause
            </label>
            <select
              className="form-control"
              name="reason"
              id="reason"
              onChange={(e) => handleChange(e)}
              value={reason}
            >
              <option value="">Select Cause</option>
              <option value="Photo is inappropriate">
                Photo is inappropriate
              </option>
              <option value="Profile content is inappropriate">
                Profile content is inappropriate
              </option>
              <option value="Profile is fake">Profile is fake</option>,
              <option value="Indecent behaviour via personal messages">
                Indecent behaviour via personal messages
              </option>
              <option value="Profile information is not true">
                Profile information is not true
              </option>
              <option value="Fake age">Fake age</option>
              <option value="Spam or scam messages">
                Spam or scam messages
              </option>
              <option value="Using multiple accounts">
                Using multiple accounts
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="row">
            <div className="form-group px-3">
              <label className="profile-label d-flex" htmlFor="message">
                Please Expalin in details
              </label>
              <textarea
                id="message"
                autoComplete="off"
                name="message"
                className="form-control"
                placeholder="Please Expalin in details..."
                onChange={(e) => handleChange(e)}
                value={message}
                rows="4"
                cols="50"
              ></textarea>
            </div>

            <div className="col-md-12 pt-3 pl-3 pr-3">
              <button
                className="btn btn-danger btn-lg w-100 rounded-pill complete-profile-button-custome-class"
                onClick={() => submitReport()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ReportModal;
