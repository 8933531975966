import React, { Component } from 'react';
import '../App.css';

export default class About extends Component {
  render(){
    return (
      <>
        
        <section className="about-us new-block pdt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <div className="img-block">
                  <div className="img-holder">
                    <img src="images/pimg.png" alt="" className="img-responsive" />
                  </div>
                </div>  
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7">
                <div className="txt-block">
                  <h2>We provide Relationships on Your Terms</h2>
                  <p>Where Sugar Babies enjoy a life of luxury by being pampered with fine dinners, exotic trips and allowances. In turn, Sugar Daddies or Mommas find beautiful members to accompany them at all times.</p>
                  <p>We want relationships to be balanced. We give our members a place for this to happen. Enjoy a life of luxury by being pampered with fine dinners, exotic trips and allowances. In turn, Sugar Daddies or Mommas find beautiful members.
                  exotic trips and allowances. In turn, Sugar Daddies or Mommas find beautiful members.</p>
                </div>
              </div> 
            </div>
          </div>
        </section>

        <section className="services au-block new-block pdtb-100">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="fz35">: OUR COMMUNITY :</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br /> mattis ullamcorper urna. Cras quis elementum</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-happiness"></i>
                  <p>Happy Clients</p>
                  <div className="counter-block"><span className="counter">3224</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-users"></i>
                  <p>Verification</p>
                  <div className="counter-block"><span className="counter">6484</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-circle"></i>
                  <p>Attention</p>
                  <div className="counter-block"><span className="counter">4531</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="block-syl1">
                  <i className="flaticon-multimedia"></i>
                  <p>Communication</p>
                  <div className="counter-block"><span className="counter">2478</span></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="history-sec new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5">
                <div className="title1">
                  <h2 className="fz35">Our History</h2>
                  <p className="fz20">Connecting singles across the world to their ideal partner</p>
                </div>
                <div className="our-history new-block">
                  <h3>Year 2014 - 2016</h3>
                  <p>Aliquam a neque tortor. Donec iaculis auctor turpis.Eporttitor tristique mattis. Vestibulum pretium eleifendrisus, scelerisque neque rhoncus in. 
                  </p>
                  <p>Curabitur volutpat rhoncus diam. Sed ac hendrerit Aliquam vestibulum augue in tortor ultrices blandit.className aptent taciti sociosqu ad litora torquent perconubia nostra</p>
                </div>
              </div>
              <div className="col-lg-7 col-md-7">
                <div className="custome-chart">
                  <canvas id="chart"></canvas>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="easy-connect new-block pdt-100">
          <div className="fixed-bg parallax" style={{background: "url('images/pbg1.png')"}}></div>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7">
                <div className="connect-block">
                  <h3 style={{fontSize:'49px'}}>Amoure connect <span>to everyone</span></h3>
                  <p className="fz35">Nulla a tempus turpis. Duis lacinia a est eu pulvinar. Nullam justo velit, vulputate et massa eu.</p>
                  <a href="#" className="c-btn-ico btn1" style={{textDecoration: 'none', color: '#fff'}}><i className="flaticon-playstore"></i>Get it on Google Play</a>
                  <a href="#" className="c-btn-ico btn2" style={{textDecoration: 'none', color: '#fff'}}><i className="flaticon-apple"></i>Downlaod on the App Store</a>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 hidden-xs">
                <div className="img-holder">
                  {/*<img src="images/mob.png" alt="" className="img-responsive" />*/}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="member-feedback new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="clr1 fz35">Amoure Member Feedback</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br /> mattis ullamcorper urna. Cras quis elementum</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testimonial owl-carousel owl-theme">
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>Fusce vitae nisi sempe ultrices sapien nec, pharetra odio. Donec efficitur hendrerit</p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm1.jpg" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>Fusce vitae nisi sempe ultrices sapien nec, pharetra odio. Donec efficitur hendrerit</p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>Fusce vitae nisi sempe ultrices sapien nec, pharetra odio. Donec efficitur hendrerit</p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm3.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="new-block">
                      <div className="block-stl3">
                        <i className="flaticon-left-quote"></i>
                        <p>Fusce vitae nisi sempe ultrices sapien nec, pharetra odio. Donec efficitur hendrerit</p>
                        <i className="flaticon-right-quotation-sign"></i>
                        <div className="arrow-down"></div>
                      </div>
                      <div className="sm-block">
                        <div className="img-holder">
                          <img src="images/tm2.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        

       
      </>
    );
  }
}
