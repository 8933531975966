import React, { Component } from 'react';
import '../App.css';

export default class SearchBoys extends Component {
  render(){
    return (
      <>
        <div>
          <section className="page-info new-block">
            <div className="fixed-bg" style={{background: "url('images/page-info-bg.jpg')"}}></div>
            <div className="overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <h2 className="clr1">Search Boys For Dating</h2>
                    <div className="clearfix"></div>
                    <ul className="list-unstyled">
                      <li><a href="#">Home</a></li>
                      <li><a href="#"> - </a></li>
                      <li><a href="#">Boys</a></li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Search by Name" />
                    <a href="#" className="sarch-member-btn"><i className="flaticon-search"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="search-form new-block pdtb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <form>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <label>I am a :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Men</option>
                                <option value="">Girl</option>
                                <option value="">other</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Looking For</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Men</option>
                                <option value="">Girl</option>
                                <option value="">other</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Location</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Usa</option>
                                <option value="">Uk</option>
                                <option value="">India</option>
                                <option value="">china</option>
                              </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Age : <span id="result_1"> 18 - 25</span></label>
                            <div className="form-group">
                              <input id="range_1" />
                                        </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label>Height : <span id="result_2"> 145cm - 210cm</span></label>
                            <div className="form-group">
                              <input id="range_2" />
                                        </div>
                          </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Weight : <span id="result_3"> 40kg - 80kg</span></label>
                            <div className="form-group">
                              <input id="range_3" />
                                        </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Education :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Graduate</option>
                                <option value="">Post Graduate</option>
                                <option value="">Studying</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Social Status :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Choose Your Preferences</option>
                                <option value="">Facebook</option>
                                <option value="">twitter</option>
                                <option value="">whatsapp</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Working in :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>All</option>
                                <option value="">It sector</option>
                                <option value="">business</option>
                                <option value="">Multimedia</option>
                              </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <label>Marital Status :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Single</option>
                                <option value="">married</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Smoking :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Yes</option>
                                <option value="">No</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label>Drinking :</label>
                            <div className="form-group">
                              <select>
                                <option value="" selected>Yes</option>
                                <option value="">No</option>
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="text-center">
                              <a href="#" className="c-btn btn1">Search</a>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="adv new-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="img-holder">
                    <img src="images/222222.jpg" alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>



          <section className="search-result-area new-block pdtb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">Search Result : 247</h2>
                    <div className="clearfix"></div>
                    <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br/> mattis ullamcorper urna. Cras quis elementum</p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img12.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img13.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img14.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img15.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img16.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img17.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img18.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img19.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img20.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img21.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img22.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img23.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img24.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img25.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img26.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img27.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img28.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img29.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img30.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img31.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img32.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img33.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img34.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4  col-xs-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img35.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <a href="#">
                        <h3 className="fz22">Tenma Shyna</h3>
                        <p>22 / F / Philippines  </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-center">
                    <a href="#" className="c-btn btn1"> View More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="lamour-suggest new-block pdtb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">Search Result : 247</h2>
                    <div className="clearfix"></div>
                    <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br/> mattis ullamcorper urna. Cras quis elementum</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img1.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img2.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / M / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img3.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img4.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img5.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img6.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img7.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / M / Philippines  </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="block-stl2">
                    <div className="img-holder">
                      <img src="images/img8.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="txt-block">
                      <h3 className="fz22">Tenma Shyna</h3>
                      <p>22 / F / Philippines  </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          

          <footer className="r-footer new-block pdtb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="newsleter">
                    <div className="title2">
                      <h2 className="clr1 fz35">Subscribe Newsleter</h2>
                      <div className="clearfix"></div>
                      <p className="fz20"> Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Email Address" />
                      <a href="#" className="subscribe-btn">Subscribe</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="footer-nav">
                    <ul className="list-unstyled">
                      <li><a href="home.html">Home</a></li>
                      <li><a href="search_girls.html">Girls</a></li>
                      <li><a href="search_girls.html">Boys</a></li>
                      <li><a href="#">Online Users</a></li>
                      <li><a href="online_search_country.html">Countries</a></li>
                      <li><a href="search_by_map.html">All World</a></li>
                      <li><a href="online_search_result.html">Filter Profile</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="contact_us.html">About us</a></li>
                      <li><a href="contact_us.html">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <span id="go_to_top" className="go-to-top"><i className="fa fa-long-arrow-up"></i></span>
          <div className="online-side-nav">
            <span className="nav-btn">
              <a href="#"><i className="flaticon-left-arrow"></i></a>
            </span>
            <div id="chat-sidebar">

              <div className="sidebar-user-box" id="100" >
                <img src="images/ou1.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Sumit Kumar Pradhan </span>
              </div> 

              <div className="sidebar-user-box" id="101" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Skptricks </span>
              </div> 

              <div className="sidebar-user-box" id="102" >
                <img src="images/ou3.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Amit Singh </span>
              </div> 

              <div className="sidebar-user-box" id="103" >
                <img src="images/ou4.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Neeraj Tiwari </span>
              </div> 

              <div className="sidebar-user-box" id="104"  >
                <img src="images/ou5.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Sorav Singh </span>
              </div> 

              <div className="sidebar-user-box" id="105" >
                <img src="images/ou6.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Lokesh Singh </span>
              </div> 

              <div className="sidebar-user-box" id="106" >
                <img src="images/ou7.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Tony </span>
              </div> 

              <div className="sidebar-user-box" id="107" >
                <img src="images/ou8.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Alex Robby </span>
              </div> 

              <div className="sidebar-user-box" id="108" >
                <img src="images/ou9.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Pragaya Mishra </span>
              </div> 

              <div className="sidebar-user-box" id="109" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Abhishek Mishra </span>
              </div> 
             
            </div>
          </div>
        </div>
      </>
    );
  }
}
