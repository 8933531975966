import React, { Component } from "react";
import "../../App.css";

import { withRouter } from "react-router";

import Auth from "../../Auth";

import axios from "../../utils/axios";
import Alert from "../../utils/Alert";
import { Link, Redirect } from "react-router-dom";

import CompleteProfileNew from "../../Views/CompleteProfileNew";

import { Modal, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor, faComments } from "@fortawesome/free-solid-svg-icons";

import "./header.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.changemenuActive = this.changemenuActive.bind(this);

    this.state = {
      lastSeenStatus: "",
      credits: 0,
      showCompleteProfileModal: false,
      uname: "",
      password: "",
      notifications: [],
      showNotifications: false,
      notificationsUnreadCount: 0,
      options: {
        home: true,
        my_photos: false,
        message: false,
        profile_visitor: false,
        crushes: false,
        my_favourite: false,
        profile: false,
        blog: false,
        about: false,
        contact: false,
      },
    };

    var path = "";

    if(typeof this.props.location!="undefined") {
      path = this.props.location.pathname.replace("/","");
      this.changemenuActive(path);
    }

    if (Auth.isAuthenticated()) {
      _this = this;
      _this.bindSocket();
      this.fetchNotificationsUnreadCount();
      // this.checkUserStatus();
      this.lastSeen();
      this.fetchCredits();
      this.fetchNotifications();
    }

    var _this = this;

    window.reloadCredits = function() {
      _this.fetchCredits();
    }
  }

  bindSocket() {

    var _this = this;

    if(typeof window.userID!="undefined") {

      console.log("new_notification_" + window.userID);
  
      this.props.socket.on("new_notification_" + window.userID, (dt)=> {
        
        _this.setState({
          notificationsUnreadCount: (parseInt(_this.state.notificationsUnreadCount) + 1)
        });
      });

    } else {
      _this = this;
      setTimeout(function() {
        _this.bindSocket();
      }, 500);
    }


    // socket.socket.on("new_notification_$userId", (dt) {
    //   if (mounted) {
    //     setState(() {
    //       _notificationCount += 1;
    //     });
    //   }
    // });
  }

  checkUserStatus() {
    if(window.showHeader && this.props.location.pathname!="/complete_profile") {
      axios.api_post("check_account_status", {}, (rsp) => {
        if (rsp.data.status == false) {
          window.location.href="/complete_profile";
          // this.setState({
          //   showCompleteProfileModal: true,
          // });
        }
      });
    }
    
  }

  changemenuActive(opt) {

    var opts = this.state.options;

    switch (opt) {
      case "home":
        opts.home = true;
        opts.my_photos = false;
        opts.message = false;
        opts.profile_visitor = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "my_photos":
        opts.my_photos = true;
        opts.home = false;
        opts.message = false;
        opts.profile_visitor = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "message":
        opts.message = true;
        opts.my_photos = false;
        opts.home = false;
        opts.profile_visitor = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "profile_visitor":
        opts.profile_visitor = true;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "my_crush":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = true;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "my_favourite":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = true;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "edit_profile":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = true;
        opts.blog = false;
        opts.about = false;
        opts.contact = false;
        break;

      case "blog":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = true;
        opts.about = false;
        opts.contact = false;
        break;

      case "about":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = true;
        opts.contact = false;
        break;

      case "contact":
        opts.profile_visitor = false;
        opts.message = false;
        opts.my_photos = false;
        opts.home = false;
        opts.my_crush = false;
        opts.my_favourite = false;
        opts.edit_profile = false;
        opts.blog = false;
        opts.about = false;
        opts.contact = true;
        break;
    }

    this.setState({
      options: opts,
    });

  }

  fetchNotificationsUnreadCount() {

    axios.api_post("get_user_notifications_count", {}, (res)=> {
      // if(res.data.status) {
        this.setState({
          notificationsUnreadCount: res.data.count
        });
      // }
    });
    
  }
  

  handleInput(event) {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  fetchNotifications() {
    axios.api_post("get_notifications", {}, (rsp) => {
      this.setState({
        notifications: rsp.data.data,
      });
    });
  }

  toggleNotification() {
    if(!this.state.showNotifications) {
      this.fetchNotifications();
    }
    
    this.setState({
      notificationsUnreadCount: 0,
      showNotifications: !this.state.showNotifications
    })
  }

  fetchCredits() {
    axios.api_post("get_user_credit_remaining", {}, (res) => {
      var cret = 0;

      if (res.data.credit) {
        cret = parseInt(res.data.credit);
      }

      this.setState({
        credits: cret,
      });
    });
  }

  login() {
    var _THIS = this;
    var username = this.state.uname;
    var password = this.state.password;
    if (username == "" && password == "") {
      Alert.showMessage("Amoure", "All fields are required", "error", "OK");
    } else if (username == "") {
      Alert.showMessage("Amoure", "Please enter username", "error", "OK");
    } else if (password == "") {
      Alert.showMessage("Amoure", "Please enter password", "error", "OK");
    } else {
      Auth.signIn(username, password, (res) => {
        if (res) {
          _THIS.props.history.replace("/home");
          window.location.reload();
        }
      });
    }
  }

  logout() {
    Auth.signout();

    // this.props.history.replace("/auth");
    // window.location.reload();
    window.location.href="https://amoure.in";
    
  }

  lastSeen() {
    var _THIS = this;
    setInterval(function () {
      if (Auth.isAuthenticated()) {
        axios.api_post("update_last_seen/", {}, (res) => {
          if (res.data.status == true) {
            _THIS.setState({
              lastSeenStatus: res.data.status,
            });
          }
        });
      }
    }, 60000);
  }

  openNotificationPopup(data) {
    const { history } = this.props;
    var user_id = btoa(data.user_id);
    history.push(`/profile_details/${user_id}`);
  }

  showCreditShop() {
    const { history } = this.props;
    history.push(`/credit_shop/`);
  }

  loadMediaCredit(showLoading) {

  }

  render() {
    return (
      <>
        <div className="loader_wrapper stl2" id="page-preloader">
          <div className="loader-center">
            <div className="spinner">
              <div className="heart heart1"></div>
              <div className="heart heart2"></div>
              <div className="heart heart3"></div>
            </div>
          </div>
        </div>
        <div id="lowCreditModal" className="modal">
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <div className="icon-box">
                  <i className="fa fa-diamond"></i>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body text-center">
                {/* <h4>Great!</h4>	 */}
                <p id="lowCreditText"></p>
                <button
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => this.showCreditShop()}
                >
                  <span>Get Diamonds</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* {this.state.showCompleteProfileModal ? (
          <div
            id="complete_profile_modal"
            show={this.state.showCompleteProfileModal}
          >
            <CompleteProfileNew></CompleteProfileNew>
          </div>
        ) : null} */}

        {(Auth.isAuthenticated() && window.showHeader) && (
          <header className="new-block header" id="header">
            <div className="topnav new-block">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <div className="top-credit-nav">
                  <div className="credit-show-holder">
                    <i className="fa fa-diamond"></i>
                    <span> Diamonds: {this.state.credits}</span>
                  </div>
                </div> */}
                    <div className="top-credit-nav">
                      <ul id="top-menu-icons" className="nav nav-pills">
                        <li role="presentation">
                          <a
                            href="javascript: void(0)"
                            id="notification_opener"
                            className={(this.state.showNotifications==true ? ' active' : ' inactive')}
                            onClick={() => this.toggleNotification()}
                            title="Alerts"
                          >
                            {this.state.notificationsUnreadCount > 0 &&
                              <span className="unreadBadge">{this.state.notificationsUnreadCount}</span>
                            }
                            <div id="mail" className={'icon-container'}>
                              <i
                                className="fa fa-bell"
                                style={{ color: "rgb(115, 149, 185)" }}
                              ></i>
                            </div>
                            <div
                              className={'dropdown-menu' + (this.state.showNotifications==true ? ' show' : '')}
                              id="notification-list"
                              aria-labelledby="notificationDropdown"
                            >
                              {this.state.notifications.map((nt, key) => {
                                return (
                                  <div
                                    className="rows notification-rows"
                                    key={key}
                                    onClick={() => this.openNotificationPopup(nt)}
                                  >
                                    <div className="col-sm-3">
                                      <div className="circle-image">
                                        <img
                                          src={nt.profile_image}
                                          alt="your_alt_text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-9">
                                      <div>
                                        {nt.username} {nt.msg}
                                      </div>
                                      <small>{nt.time}</small>
                                    </div>
                                  </div>
                                );
                              })}

                              {this.state.notifications.length==0 &&
                                <div className="no_notification_text">No Notifications</div>
                              }
                            </div>
                          </a>
                        </li>
                        <li role="presentation">
                          <Link
                            to={`/profile_visitor`}
                            style={{ color: "white" }}
                          >
                            <div
                              id="glee_mail"
                              className="icon-container inactive"
                            >
                              <i
                                className="fa fa-eye"
                                style={{ color: "rgb(115, 149, 185)" }}
                              ></i>
                            </div>
                          </Link>
                        </li>
                        <li
                          role="presentation"
                          style={{ backgroundColor: "rgb(27, 41, 55)", boxShadow: "2px 2px 5px #0e1920 inset" }}
                        >
                          <Link to={`/credit_shop`} style={{ color: "white" }}>
                            <i className="fa fa-diamond"></i>
                            <span> Diamonds: {this.state.credits}</span>
                          </Link>
                        </li>
                      </ul>
                      
                      <div className="logout_holder">
                        {/*{!Auth.isAuthenticated() &&
                    <div className="row pull-right loginFormAlignment">
                      <div className="textInputAlignment">
                        <input type="number" 
                          name="uname" 
                          id="uname" 
                          className="loginInputAlignment" 
                          onChange={(e)=>this.handleInput(e)}
                          placeholder="Enter phone number"/>
                      </div>

                      <div className="textInputAlignment">
                        <input type="password" 
                          name="password" 
                          id="password" 
                          className="loginInputAlignment" 
                          onChange={(e)=>this.handleInput(e)}
                          placeholder="Enter password"/>
                      </div>

                      <div>
                        <span className="top-nav-signup_ligin"><a href="javascript: void(0)" onClick={this.login}>Login</a></span>
                      </div>
                    </div>
                  }*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-nav new-block">
              <div className="overlay"></div>
              <div className="container-fluid pad0">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="/images/logo3.png"
                          alt="logo"
                          className="img-responsive"
                        />
                      </a>
                    </div>
                    <a href="#" className="nav-opener">
                      <i className="fa fa-bars"></i>
                    </a>
                    <div className="nav-block">
                      <nav className="nav">
                        <ul className="list-unstyled">
                          <li>
                            {/*<a href="/home">Home</a>*/}
                            <Link
                              to={`/home`}
                              className={
                                "navLinkAlignment" +
                                (this.state.options.home ? " active" : "")
                              }
                              onClick={() => this.changemenuActive("home")}
                            >
                              Home
                            </Link>
                          </li>

                          <>
                            <li>
                              {/*<a href="/my_photos"  onClick={()=>this.changemenuActive('my_photos')}>My Photos</a>*/}
                              <Link
                                to={`/my_photos`}
                                className={
                                  "navLinkAlignment" +
                                  (this.state.options.my_photos ? " active" : "")
                                }
                                onClick={() =>
                                  this.changemenuActive("my_photos")
                                }
                              >
                                My Photos
                              </Link>
                            </li>
                            {/* <li>
                          <a href="#" className={'navLinkAlignment'+(this.state.options.message ? ' active' : '')} onClick={()=>this.changemenuActive('message')}>Message</a>
                        </li> */}
                            <li>
                              {/*<a href="/profile_visitor" className={'navLinkAlignment'+(this.state.options.home ? ' active' : '')} onClick={()=>this.changemenuActive('profile')}>Profile Visitors</a>*/}
                              <Link
                                to={`/profile_visitor`}
                                className={
                                  "navLinkAlignment" +
                                  (this.state.options.profile_visitor
                                    ? " active"
                                    : "")
                                }
                                onClick={() =>
                                  this.changemenuActive("profile_visitor")
                                }
                              >
                                Profile Visitors
                              </Link>
                            </li>
                            <li>
                              {/*<a href="/my_crush" className={'navLinkAlignment'+(this.state.options.home ? ' active' : '')} onClick={()=>this.changemenuActive('crushes')}>My Crushes</a>*/}
                              <Link
                                to={`/my_crush`}
                                className={
                                  "navLinkAlignment" +
                                  (this.state.options.my_crush ? " active" : "")
                                }
                                onClick={() => this.changemenuActive("my_crush")}
                              >
                                My Crushes
                              </Link>
                            </li>
                            <li>
                              {/*<a href="/my_favourite" className={'navLinkAlignment'+(this.state.options.home ? ' active' : '')} onClick={()=>this.changemenuActive('favourite')}>My Favourites</a>*/}
                              <Link
                                to={`/my_favourite`}
                                className={
                                  "navLinkAlignment" +
                                  (this.state.options.my_favourite
                                    ? " active"
                                    : "")
                                }
                                onClick={() =>
                                  this.changemenuActive("my_favourite")
                                }
                              >
                                My Favourites
                              </Link>
                            </li>
                            <li>
                              {/*<a href="/edit_profile" className={'navLinkAlignment'+(this.state.options.home ? ' active' : '')} onClick={()=>this.changemenuActive('profile')}>My Profile</a>*/}
                              <Link
                                to={`/edit_profile`}
                                className={
                                  "navLinkAlignment" +
                                  (this.state.options.edit_profile ? " active" : "")
                                }
                                onClick={() => this.changemenuActive("edit_profile")}
                              >
                                My Profile
                              </Link>
                            </li>

                            <li>
                              <a
                                href="javascript: void(0)"
                                className="top-nav-signup_ligin"
                                onClick={this.logout}
                              >
                                Logout
                              </a>
                            </li>
                          </>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}
      </>
    );
  }
}

export default withRouter(Header);
