import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Alert from "../utils/Alert";
var THIS;
class Registration extends Component {
  constructor(props) {
    super(props);
    this.changeGander = this.changeGander.bind(this);
    this.genderAccept = this.genderAccept.bind(this);
    this.changeGanderLooking = this.changeGanderLooking.bind(this);
    this.lookingGenderAccept = this.lookingGenderAccept.bind(this);
    this.registration = this.registration.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.state = {
      options: {
        male: false,
        female: false,
      },
      gender: "",
      genderStatus: false,
      lookingOptions: {
        men: false,
        women: false,
        both: false,
      },
      lookingGender: "",
      lookingGenderStatus: false,
      username: "",
      mobile: "",
      password: "",
      state: "",
      city: "",
      age: "",
    };
    THIS = this;
  }

  changeGander(opt) {
    var opts = this.state.options;

    switch (opt) {
      case "male":
        opts.male = true;
        opts.female = false;
        break;

      case "female":
        opts.female = true;
        opts.male = false;
        break;
    }

    this.setState({
      options: opts,
    });
  }

  genderAccept() {
    var gender = this.state.options;
    if (gender.male == true) {
      gender = "male";
    } else if (gender.female == true) {
      gender = "female";
    }

    this.setState({
      gender: gender,
      genderStatus: true,
    });
  }

  changeGanderLooking(lookopt) {
    var lookopts = this.state.lookingOptions;

    switch (lookopt) {
      case "men":
        lookopts.men = true;
        lookopts.women = false;
        lookopts.both = false;
        break;

      case "women":
        lookopts.women = true;
        lookopts.men = false;
        lookopts.both = false;
        break;

      case "both":
        lookopts.both = true;
        lookopts.women = false;
        lookopts.men = false;
        break;
    }

    this.setState({
      lookingOptions: lookopts,
      // lookingGenderStatus:true,
    });

    // console.log(this.state.lookingOptions)
  }

  lookingGenderAccept() {
    var lookgen = this.state.lookingOptions;

    // console.log('lookopts', lookopts);
    if (lookgen.men == true) {
      lookgen = "men";
    } else if (lookgen.women == true) {
      lookgen = "women";
    } else if (lookgen.both == true) {
      lookgen = "both";
    }

    this.setState({
      lookingGender: lookgen,
      lookingGenderStatus: true,
    });
  }

  handleInput(event) {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  // validateEmail(email) {
  //   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // }

  registration() {
    var genderUser = this.state.gender;
    var lookingGenderUser = this.state.lookingGender;
    var username = this.state.username;
    var mobile = this.state.mobile;
    var password = this.state.password;
    var state = this.state.state;
    var city = this.state.city;
    var age = this.state.age;

    if (
      username == "" &&
      mobile == "" &&
      password == "" &&
      state == "" &&
      city == "" &&
      age == ""
    ) {
      Alert.showMessage("Amoure", "All fields are required", "error", "OK");
    } else if (username == "") {
      Alert.showMessage("Amoure", "Please enter email", "error", "OK");
    } else if (mobile == "") {
      Alert.showMessage("Amoure", "Please enter mobil number", "error", "OK");
    } else if (password == "") {
      Alert.showMessage("Amoure", "Please enter Password", "error", "OK");
    } else if (state == "") {
      Alert.showMessage("Amoure", "Please enter state", "error", "OK");
    } else if (city == "") {
      Alert.showMessage("Amoure", "Please enter city", "error", "OK");
    } else if (age == "") {
      Alert.showMessage("Amoure", "Please enter age", "error", "OK");
    } else {
      var _THIS = this;

      Auth.registration(
        username,
        mobile,
        password,
        city,
        state,
        age,
        genderUser,
        lookingGenderUser,
        (res) => {
          console.log("res", res);
          if (res) {
            _THIS.props.history.replace("/home");
            window.location.reload();
          }
        }
      );
    }
  }

  render() {
    return (
      <>
        {this.state.genderStatus != true && (
          <section className="services au-block new-block pdtb-100">
            <div className="overlay"></div>
            {/*<div>{this.state.gender}</div>*/}
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title2">
                    <h2 className="fz35">I am</h2>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div
                      className={
                        "block-syl2" +
                        (this.state.options.male ? " active" : "")
                      }
                      onClick={() => this.changeGander("male")}
                    >
                      <div className="row reg_content_alignment">
                        <p
                          className={
                            "reg_icon_container" +
                            (this.state.options.male ? " active" : "")
                          }
                        >
                          <i
                            className={
                              "fa fa-male icon_reg_style" +
                              (this.state.options.male ? " active" : "")
                            }
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                      <div className="row reg_content_alignment">
                        <p
                          className={
                            "reg_content" +
                            (this.state.options.male ? " active" : "")
                          }
                        >
                          Male
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div
                      className={
                        "block-syl2" +
                        (this.state.options.female ? " active" : "")
                      }
                      onClick={() => this.changeGander("female")}
                    >
                      <div className="row reg_content_alignment">
                        <p
                          className={
                            "reg_icon_container" +
                            (this.state.options.female ? " active" : "")
                          }
                        >
                          <i
                            className={
                              "fa fa-female icon_reg_style" +
                              (this.state.options.female ? " active" : "")
                            }
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                      <div className="row reg_content_alignment">
                        <p
                          className={
                            "reg_content" +
                            (this.state.options.female ? " active" : "")
                          }
                        >
                          Female
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ justifyContent: "center" }}>
                <div className="connect-block button_alignment">
                  <button
                    href="#"
                    className="c-btn-ico btn1"
                    onClick={() => this.genderAccept()}
                    style={{ color: "#fff" }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}

        {this.state.genderStatus == true &&
          this.state.lookingGenderStatus != true && (
            <section className="services au-block new-block pdtb-100">
              <div className="overlay"></div>
              {/*<div>{this.state.gender}</div>*/}
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="title2">
                      <h2 className="fz35">Looking For</h2>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div
                        className={
                          "block-syl2" +
                          (this.state.lookingOptions.men ? " active" : "")
                        }
                        onClick={() => this.changeGanderLooking("men")}
                      >
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_icon_container" +
                              (this.state.lookingOptions.men ? " active" : "")
                            }
                          >
                            <i
                              className={
                                "fa fa-male icon_reg_style" +
                                (this.state.lookingOptions.men ? " active" : "")
                              }
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_content" +
                              (this.state.lookingOptions.men ? " active" : "")
                            }
                          >
                            Men
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div
                        className={
                          "block-syl2" +
                          (this.state.lookingOptions.women ? " active" : "")
                        }
                        onClick={() => this.changeGanderLooking("women")}
                      >
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_icon_container" +
                              (this.state.lookingOptions.women ? " active" : "")
                            }
                          >
                            <i
                              className={
                                "fa fa-female icon_reg_style" +
                                (this.state.lookingOptions.women
                                  ? " active"
                                  : "")
                              }
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_content" +
                              (this.state.lookingOptions.women ? " active" : "")
                            }
                          >
                            Women
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div
                        className={
                          "block-syl2" +
                          (this.state.lookingOptions.both ? " active" : "")
                        }
                        onClick={() => this.changeGanderLooking("both")}
                      >
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_icon_container" +
                              (this.state.lookingOptions.both ? " active" : "")
                            }
                          >
                            <i
                              className={
                                "fa fa-transgender icon_reg_style" +
                                (this.state.lookingOptions.both
                                  ? " active"
                                  : "")
                              }
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                        <div className="row reg_content_alignment">
                          <p
                            className={
                              "reg_content" +
                              (this.state.lookingOptions.both ? " active" : "")
                            }
                          >
                            Both
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="connect-block button_alignment">
                    <button
                      href="#"
                      className="c-btn-ico btn1"
                      onClick={() => this.lookingGenderAccept()}
                      style={{ color: "#fff" }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </section>
          )}

        {this.state.lookingGenderStatus == true &&
          this.state.genderStatus == true &&
          this.state.genderStatus == true && (
            <section className="lamour-suggest new-block">
              <div className="container">
                <div className="row reg_container_alignment">
                  <div className="container register">
                    <div className="row">
                      <div className="col-md-3 register-left">
                        <div className="sideContentAlignment">
                          <h3>Welcome Amoure</h3>
                          <p style={{ color: "#fff" }}>100% Entertainment</p>
                        </div>
                      </div>
                      <div className="col-md-9 register-right">
                        <div className="row register-form">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="labelAlignment">
                                Username :
                              </label>
                              <input
                                type="text"
                                name="username"
                                id="username"
                                className="form-control"
                                placeholder="Enter username"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="labelAlignment">
                                Mobile No :
                              </label>
                              <input
                                type="number"
                                name="mobile"
                                id="mobile"
                                className="form-control"
                                placeholder="Enter Mobile No"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="labelAlignment">
                                Password :
                              </label>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control"
                                placeholder="Enter Password"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="labelAlignment">State :</label>
                              <input
                                type="text"
                                name="state"
                                id="state"
                                className="form-control"
                                placeholder="Enter State"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>

                            <div className="form-group">
                              <label className="labelAlignment">City :</label>
                              <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-control"
                                placeholder="Enter City"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="labelAlignment">Age :</label>
                              <input
                                type="text"
                                name="age"
                                id="age"
                                className="form-control"
                                placeholder="Enter Age"
                                onChange={(e) => this.handleInput(e)}
                              />
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ justifyContent: "center", width: "100%" }}
                          >
                            <input
                              type="submit"
                              className="btnRegister"
                              onClick={() => this.registration()}
                              value="Register"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
      </>
    );
  }
}

export default withRouter(Registration);
