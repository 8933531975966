import axios from 'axios';
// import swal from 'sweetalert';

const instance = axios.create({
	baseURL: 'https://amoure.in/backend/apiWeb1/',
	headers: {
        common: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('auth_token'),
        },
    },
	
});

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.getBaseUrl = function() {
	return "https://amoure.in/backend/apiWeb1/";
};

instance.api_post = function(path, params, callback) {

	var frm = new FormData();

	Object.keys(params).forEach(function(key) {
		frm.append(key, params[key]);
	});

	if(global.userID) {
		frm.append("user_id", global.userID);
	}

	axios({
	    method: 'post',
	    url: this.getBaseUrl()+path,
	    data: frm,
	    // withCredentials: true,
	    // credentials: 'include',
	    headers: {
	    	'Content-Type': 'multipart/form-data',
	    	// "Content-Type": "application/x-www-form-urlencoded",
	    	Authorization: 'Bearer '+localStorage.getItem('token'),
	    }
    }).then(response => {
      
  		callback(response);
	});

}


export default instance;