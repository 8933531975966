import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
import Auth from "../Auth";
import axios from "../utils/axios";
import Alert from "../utils/Alert";
// import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class CreditShop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shop_items: [],
    };

    this.fileInputRef = React.createRef();

    this.getCreditShop();
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  getCreditShop() {
    axios.api_post("get_credit_shop", {}, (res) => {
      if (res.data.status == true) {
        this.setState({
          shop_items: res.data.data,
        });
      }
    });
  }

  centerWindow(url, width, height) {

    const x = window.innerWidth / 2 - width / 2;
    const y = window.innerHeight / 2 - height / 2;

    var newWindow = window.open(url, 'Credit Payment', `width=${width},height=${height},left=${x},top=${y}`);

    window.addEventListener('message', function(event) {
      window.location.reload();
   });

  }

  proceedToPayment = (plan_data) => {

    var url = "https://amoure.in/backend/paymentProcess/initiate_payment/"+plan_data.plan+"/"+localStorage.getItem('token');

    this.centerWindow(url, 1000, 800);

  };

  render() {
    return (
      <>
        <section className="lamour-suggest new-block content_align">
          <div className="container">
            <div className="row">
              {this.state.shop_items.map((dt, index) => {
                return (
                  <div class="col-md-4 col-sm-6 mb-4">
                    <div class="p-4 text-center credit_item rounded-lg">
                      <i class="fa fa-diamond text-white fa-2x"></i>
                      <h5 class="text-white mt-3">{dt.coins}</h5>
                      <button
                        type="button"
                        class="btn btn-outline-light btn-lg rounded-pill px-4 py-2 mt-3"
                        onClick={() => this.proceedToPayment(dt)}
                      >
                        ₹ {dt.price_web}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(CreditShop);
