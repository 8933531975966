import React, { Component } from 'react';
import '../App.css';

export default class Blog extends Component {
  render(){
    return (
      <>
        <section className="page-info new-block">
          <div className="fixed-bg" style={{background: "url('images/page-info-bg.jpg')"}}></div>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <h2 className="clr1">Blog</h2>
                  <div className="clearfix"></div>
                  <ul className="list-unstyled">
                    <li><a href="#">Home</a></li>
                    <li><a href="#"> - </a></li>
                    <li><a href="#">Blog</a></li>
                  </ul>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by Name" />
                  <a href="#" className="sarch-member-btn"><i className="flaticon-search"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-page new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img88.jpg" alt="" className="img-responsive" />
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img89.jpg" alt="" className="img-responsive"/>
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img90.jpg" alt="" className="img-responsive"/>
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img91.jpg" alt="" className="img-responsive"/>
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img92.jpg" alt="" className="img-responsive"/>
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-lg-12">
                <div className="blogs">
                  <div className="img-holder">
                    <img src="images/img93.jpg" alt="" className="img-responsive"/>
                  </div>
                  <div className="blog-txt">
                    <div className="inner-txt-block">
                      <a href="#"><h2>Nulla facilisis nisl vitvolutpat placerat. Pellent habitant morbi tristique .</h2></a>
                      <ul className="list-unstyled profile-contact">
                        <li><a href="#"><i className="fa fa-user"></i>Tenma Shyna</a></li>
                        <li><a href="#"><i className="fa fa-clock-o"></i>Dec 02 , 2018  06:45 pm</a></li>
                        <li><a href="#"><i className="fa fa-heart"></i>275</a></li>
                        <li><a href="#"><i className="fa fa-comment-o"></i>66</a></li>
                      </ul>
                      <p>Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor tristique mattis. Vestibulum pretium eleifend risus, scelerisque neque rhoncus in. </p>
                      <a href="blog_single.html" className="c-btn btn1">Read More</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="text-center">
                  <a href="#" className="c-btn btn1">View More</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="r-footer new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="newsleter">
                  <div className="title2">
                    <h2 className="clr1 fz35">Subscribe Newsleter</h2>
                    <div className="clearfix"></div>
                    <p className="fz20"> Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Email Address" />
                    <a href="#" className="subscribe-btn">Subscribe</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="footer-nav">
                  <ul className="list-unstyled">
                    <li><a href="home.html">Home</a></li>
                    <li><a href="search_girls.html">Girls</a></li>
                    <li><a href="search_girls.html">Boys</a></li>
                    <li><a href="#">Online Users</a></li>
                    <li><a href="online_search_country.html">Countries</a></li>
                    <li><a href="search_by_map.html">All World</a></li>
                    <li><a href="online_search_result.html">Filter Profile</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="contact_us.html">About us</a></li>
                    <li><a href="contact_us.html">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <span id="go_to_top" className="go-to-top"><i className="fa fa-long-arrow-up"></i></span>
          <div className="online-side-nav">
            <span className="nav-btn">
              <a href="#"><i className="flaticon-left-arrow"></i></a>
            </span>
            <div id="chat-sidebar">

              <div className="sidebar-user-box" id="100" >
                <img src="images/ou1.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Sumit Kumar Pradhan </span>
              </div> 

              <div className="sidebar-user-box" id="101" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Skptricks </span>
              </div> 

              <div className="sidebar-user-box" id="102" >
                <img src="images/ou3.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Amit Singh </span>
              </div> 

              <div className="sidebar-user-box" id="103" >
                <img src="images/ou4.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Neeraj Tiwari </span>
              </div> 

              <div className="sidebar-user-box" id="104"  >
                <img src="images/ou5.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Sorav Singh </span>
              </div> 

              <div className="sidebar-user-box" id="105" >
                <img src="images/ou6.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Lokesh Singh </span>
              </div> 

              <div className="sidebar-user-box" id="106" >
                <img src="images/ou7.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Tony </span>
              </div> 

              <div className="sidebar-user-box" id="107" >
                <img src="images/ou8.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Alex Robby </span>
              </div> 

              <div className="sidebar-user-box" id="108" >
                <img src="images/ou9.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Pragaya Mishra </span>
              </div> 

              <div className="sidebar-user-box" id="109" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Abhishek Mishra </span>
              </div> 
             
            </div>
          </div>
      </>
    );
  }
}
