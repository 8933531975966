import React, { Component } from 'react';
import '../App.css';

export default class SearchOnlineUsers extends Component {
  render(){
    return (
      <>
        <section className="page-info new-block">
          <div className="fixed-bg" style={{background: "url('images/page-info-bg.jpg')"}}></div>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <h2 className="clr1">Search Online Users</h2>
                  <div className="clearfix"></div>
                  <ul className="list-unstyled">
                    <li><a href="#">Home</a></li>
                    <li><a href="#"> - </a></li>
                    <li><a href="#">Online Users</a></li>
                  </ul>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by Name" />
                  <a href="#" className="sarch-member-btn"><i className="flaticon-search"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="search-form om-m-now new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title2">
                  <h2 className="fz35">Members Online Now</h2>
                  <div className="clearfix"></div>
                  <p className="fz20">Aliquam a neque tortor. Donec iaculis auctor turpis. Eporttitor<br /> mattis ullamcorper urna. Cras quis elementum</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <form>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label>I am a :</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Men</option>
                              <option value="">Girl</option>
                              <option value="">other</option>
                            </select>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label>Looking For</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Choose Your Preferences</option>
                              <option value="">Male</option>
                            <option value="">Female</option>
                            <option value="">Other</option>
                            </select>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Location</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Choose Your Preferences</option>
                              <option value="">Usa</option>
                              <option value="">Uk</option>
                              <option value="">India</option>
                              <option value="">china</option>
                            </select>
                          </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="list-grid new-block pdb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-nav">
                  <button className="btn btn1 filter-2" data-filter="all">All</button>
                  <button className="btn btn1 filter-2" data-filter=".category-1">Same country</button>
                  <button className="btn btn1 filter-2" data-filter=".category-2">People Near You</button>
                  <button className="btn btn1 filter-2" data-filter=".category-3">All World</button>
                </div>
                <div className="list-grid-btns">
                  <button className="btn list-btn active"><i className="flaticon-menu"></i></button>
                  <button className="btn grid-btn"><i className="flaticon-list"></i></button>
                </div>
              </div>
              <div className="col-lg-12 pd0">
                <div id="MixItUp2" className="custom-contaier">
                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img1.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>

                  </div>
                  
                  <div className="mix category-2 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img2.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / M / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>M / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img3.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img4.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img5.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img6.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>
     
                  <div className="mix category-2 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img7.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / M / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>M / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img8.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img36.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img37.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / M / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>M / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img38.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / M / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>M / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img39.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-3 category-2 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img40.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img41.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-2 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img42.jpg" alt="" className="img-responsive"/>
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / M / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>M / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>

                  <div className="mix category-1 category-3 all">
                    <div className="block-stl2">
                      <div className="img-holder">
                        <img src="images/img43.jpg" alt="" className="img-responsive" />
                      </div>
                      <div className="txt-block">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>22 / F / Philippines  </p>
                      </div>
                      <div className="txt-block2">
                        <h3 className="fz22"><a href="#">Tenma Shyna</a></h3>
                        <p>F / 22 / Philippines  </p>
                        <p className="ab-txt">There's tons of singles. We're one ofthe biggest dating sites on earth, andit's all totally.</p>
                        <a href="#" className="c-btn btn3">contact</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="text-center">
                  <a href="#" className="c-btn btn3">View More</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="r-footer new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="newsleter">
                  <div className="title2">
                    <h2 className="clr1 fz35">Subscribe Newsleter</h2>
                    <div className="clearfix"></div>
                    <p className="fz20"> Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Email Address" />
                    <a href="#" className="subscribe-btn">Subscribe</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="footer-nav">
                  <ul className="list-unstyled">
                    <li><a href="home.html">Home</a></li>
                    <li><a href="search_girls.html">Girls</a></li>
                    <li><a href="search_girls.html">Boys</a></li>
                    <li><a href="#">Online Users</a></li>
                    <li><a href="online_search_country.html">Countries</a></li>
                    <li><a href="search_by_map.html">All World</a></li>
                    <li><a href="online_search_result.html">Filter Profile</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="contact_us.html">About us</a></li>
                    <li><a href="contact_us.html">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <span id="go_to_top" className="go-to-top"><i className="fa fa-long-arrow-up"></i></span>
        <div className="online-side-nav">
          <span className="nav-btn">
            <a href="#"><i className="flaticon-left-arrow"></i></a>
          </span>
          <div id="chat-sidebar">

            <div className="sidebar-user-box" id="100" >
              <img src="images/ou1.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette"></i>
              <span className="slider-username">Sumit Kumar Pradhan </span>
            </div> 

            <div className="sidebar-user-box" id="101" >
              <img src="images/ou2.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Skptricks </span>
            </div> 

            <div className="sidebar-user-box" id="102" >
              <img src="images/ou3.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette"></i>
              <span className="slider-username">Amit Singh </span>
            </div> 

            <div className="sidebar-user-box" id="103" >
              <img src="images/ou4.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Neeraj Tiwari </span>
            </div> 

            <div className="sidebar-user-box" id="104"  >
              <img src="images/ou5.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Sorav Singh </span>
            </div> 

            <div className="sidebar-user-box" id="105" >
              <img src="images/ou6.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Lokesh Singh </span>
            </div> 

            <div className="sidebar-user-box" id="106" >
              <img src="images/ou7.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette"></i>
              <span className="slider-username">Tony </span>
            </div> 

            <div className="sidebar-user-box" id="107" >
              <img src="images/ou8.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Alex Robby </span>
            </div> 

            <div className="sidebar-user-box" id="108" >
              <img src="images/ou9.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette"></i>
              <span className="slider-username">Pragaya Mishra </span>
            </div> 

            <div className="sidebar-user-box" id="109" >
              <img src="images/ou2.jpg" alt=" " />
              <i className="flaticon-circular-shape-silhouette active"></i>
              <span className="slider-username">Abhishek Mishra </span>
            </div> 
          </div>
        </div>
      </>
    );
  }
}
