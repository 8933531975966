import React, { memo, useState } from "react";
import "./index.css";

const Input = ({
  variant,
  name,
  value,
  label,
  placeholder,
  errors,
  list,
  selectLabel,
  prefix,
  postfix,
  handleChange,
  handleBlur,
  handleFocus,
  handleClick,
  numbersOnly,
  rows,
  mandatory,
  handleIncrease,
  handleDecrease,
  radioBtnId,
  id,
  className,
  disabled,
  infoIconShow,
  defaultChecked,
  defaultValue,
  readonly = false,
  disabledInput = false,
  toolTips,
  showTextColor = false,
  tooltipName = "tooltiptext",
  showBorderValidation = false,
}) => {
  const [onFocus, setOnFocus] = useState(false);
  const style = {
    display: "block",
  };

  if (name === "courtRating") {
    tooltipName = "tooltiptextright tooltip-right";
  }

  return (
    <>
      {variant === "text" && !prefix && !postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <input
            type={variant}
            name={name}
            value={value}
            className={`form-control ${onFocus && errors && "error"}`}
            placeholder={placeholder}
            id={`input-${name}`}
            onChange={(e) => handleChange && handleChange(e)}
            onBlur={(e) => {
              if (handleBlur) {
                handleBlur(e);
              }
              setOnFocus(false);
            }}
            onFocus={(e) => {
              setOnFocus(true);
              if (handleFocus) {
                handleFocus(e);
              }
            }}
            readOnly={readonly && true}
            style={{ color: `${showTextColor && value}` }}
          />
          {errors && (
            <div className="invalid-feedback" style={style}>
              {errors}
            </div>
          )}
        </>
      )}
      {variant === "dropdown" && (
        <>
          <label htmlFor={name} className="form-label d-flex">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
            {toolTips && (
              <span className="tooltips bi bi-info-circle-fill info-icon-alignment text-secondary">
                <span className={tooltipName}>{toolTips && toolTips}</span>
              </span>
            )}
          </label>
          <select
            disabled={disabled}
            defaultValue={defaultValue}
            name={name}
            id={`dropdown-${name}`}
            className={`form-control ${errors && "error"}`}
            // onChange={(e) => console.log({ e })}
            onChange={(e) => {
              alert();
            }}
            onMouseLeave={(e) => {
              handleClick(e);
            }}
            onBlur={(e) => {
              if (handleBlur) {
                handleBlur(e);
              }
              setOnFocus(false);
            }}
            onFocus={(e) => {
              setOnFocus(true);
              if (handleFocus) {
                handleFocus(e);
              }
            }}
            value={value}
          >
            {selectLabel && <option value="">{selectLabel}</option>}
            {list?.length &&
              list.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          {errors && (
            <div className="invalid-feedback" style={style}>
              {errors}
            </div>
          )}
        </>
      )}
      {variant === "text" && prefix && !postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <div className="input-group mb-2">
            <span className="input-group-text">{prefix}</span>
            <input
              type={variant}
              name={name}
              value={value}
              className={`form-control ${errors && "error"}`}
              aria-label="Amount (to the nearest dollar)"
              placeholder={placeholder}
              id={`input-${name}`}
              onChange={(e) => handleChange && handleChange(e)}
              onBlur={(e) => {
                if (handleBlur) {
                  handleBlur(e);
                }
                setOnFocus(false);
              }}
              onFocus={(e) => {
                setOnFocus(true);
                if (handleFocus) {
                  handleFocus(e);
                }
              }}
            />
            {errors && (
              <div className="invalid-feedback" style={style}>
                {errors}
              </div>
            )}
          </div>
        </>
      )}
      {variant === "text" && !prefix && postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <div className="input-group mb-3">
            <input
              type={variant}
              name={name}
              value={value}
              className={`form-control ${errors && "error"}`}
              aria-label="Amount (to the nearest dollar)"
              placeholder={placeholder}
              id={`input-${name}`}
              onChange={(e) => handleChange && handleChange(e)}
              onBlur={(e) => {
                if (handleBlur) {
                  handleBlur(e);
                }
                setOnFocus(false);
              }}
              onFocus={(e) => {
                setOnFocus(true);
                if (handleFocus) {
                  handleFocus(e);
                }
              }}
              disabled={disabledInput && disabledInput}
            />
            <span
              className="input-group-text"
              disabled={disabledInput && disabledInput}
            >
              {postfix}
            </span>
            {errors && (
              <div className="invalid-feedback" style={style}>
                {errors}
              </div>
            )}
          </div>
        </>
      )}
      {variant === "text" && prefix && postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <div className="input-group mb-3">
            <span
              onClick={(e) => {
                !disabledInput && handleDecrease(name, value);
              }}
              className="input-group-text cursor"
            >
              {prefix}
            </span>
            <input
              type={variant}
              name={name}
              maxLength="1"
              value={Number(value)}
              className={`form-control ${errors && "error"} text-center`}
              aria-label="Amount (to the nearest dollar)"
              placeholder={placeholder}
              id={`input-${name}`}
              onChange={(e) => handleChange && handleChange(e)}
              onBlur={(e) => {
                if (handleBlur) {
                  handleBlur(e);
                }
                setOnFocus(false);
              }}
              onFocus={(e) => {
                setOnFocus(true);
                if (handleFocus) {
                  handleFocus(e);
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key) && numbersOnly) {
                  event.preventDefault();
                }
              }}
              disabled={true}
            />
            <span
              onClick={(e) => {
                !disabledInput && handleIncrease(name, value);
              }}
              className="input-group-text cursor"
            >
              {postfix}
            </span>
            {errors && (
              <div className="invalid-feedback" style={style}>
                {errors}
              </div>
            )}
          </div>
        </>
      )}
      {variant === "textarea" && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <textarea
            name={name}
            value={value}
            rows={rows}
            className="form-control mb-2"
            onChange={(e) => handleChange && handleChange(e)}
            onBlur={(e) => {
              if (handleBlur) {
                handleBlur(e);
              }
              setOnFocus(false);
            }}
            onFocus={(e) => {
              setOnFocus(true);
              if (handleFocus) {
                handleFocus(e);
              }
            }}
            disabled={disabledInput && disabledInput}
          ></textarea>
          {errors && (
            <div className="invalid-feedback" style={style}>
              {errors}
            </div>
          )}
        </>
      )}
      {variant === "radio" && (
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name={name}
            id={radioBtnId}
            value={value}
            checked={defaultChecked}
            onChange={(e) => handleChange && handleChange(e)}
            disabled={disabledInput && disabledInput}
          />
          <label className="form-check-label" htmlFor={radioBtnId}>
            {" "}
            {label}{" "}
          </label>
        </div>
      )}
      {variant === "switch" && (
        <div>
          <label className="switch">
            <input
              type="checkbox"
              role="switch"
              name={name}
              value={value}
              id={radioBtnId}
              checked={value}
              onChange={(e) => handleChange && handleChange(e)}
              disabled={disabledInput && disabledInput}
            />
            <span className="slider round"></span>
          </label>
          <label className="form-check-label" htmlFor={radioBtnId}>
            {label}
          </label>
        </div>
      )}
      {variant === "checkbox" && (
        <div className={`form-check ${className}`}>
          <input
            className="form-check-input teamSelected"
            name={name}
            type="checkbox"
            id={id}
            value={value}
            onChange={handleChange}
            checked={defaultChecked}
          />
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      {variant === "password" && !prefix && !postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <input
            type={variant}
            name={name}
            value={value}
            className={`form-control ${onFocus && errors && "error"}`}
            placeholder={placeholder}
            id={`input-${name}`}
            onChange={(e) => handleChange && handleChange(e)}
            onBlur={(e) => {
              if (handleBlur) {
                handleBlur(e);
              }
              setOnFocus(false);
            }}
            onFocus={(e) => {
              setOnFocus(true);
              if (handleFocus) {
                handleFocus(e);
              }
            }}
          />
          {errors && (
            <div className="invalid-feedback" style={style}>
              {errors}
            </div>
          )}
        </>
      )}
      {variant === "time" && !prefix && postfix && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
            {mandatory && <span className="asterisk">{" *"}</span>}
          </label>
          <div className="input-group mb-3">
            <input
              type={variant}
              name={name}
              value={value}
              className={`form-control ${errors && "error"} ${
                showBorderValidation && "custom-border"
              }`}
              aria-label="Amount (to the nearest dollar)"
              placeholder={placeholder}
              id={`input-${name}`}
              onChange={(e) => handleChange && handleChange(e)}
              onBlur={(e) => {
                if (handleBlur) {
                  handleBlur(e);
                }
                setOnFocus(false);
              }}
              onFocus={(e) => {
                setOnFocus(true);
                if (handleFocus) {
                  handleFocus(e);
                }
              }}
              disabled={disabledInput && disabledInput}
            />
            <span className="input-group-text">{postfix}</span>
            {errors && (
              <div className="invalid-feedback" style={style}>
                {errors}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default memo(Input);
