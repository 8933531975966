import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../App.css";
// import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import AmoureLoader from "./AmoureLoader";
import Alert from "../utils/Alert";

import _ from "lodash";

const Profile = () => {
  const [fieldOption, setfieldOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState({
    username: "",
    age: "",
    city: "",
    state: "",
    bio: "",
  });

  const getFieldValue = useCallback(() => {
    setIsLoading(true);
    axios.api_post("get_profile_fields_option", {}, (res) => {
      if (res.data.status === true) {
        var fieldValue = res.data.field_options;

        if (Object.keys(fieldValue)?.length) {
          const modifyData = Object.keys(fieldValue).map((item) => {
            return {
              ...fieldValue[item],
              options: fieldValue[item].options?.map((opt) => {
                return {
                  ...opt,
                  isSelect: false,
                };
              }),
            };
          });
          setfieldOption(modifyData);
          getCurrentUser(modifyData);
        }
      }
    });
  }, []);

  const getCurrentUser = useCallback(
    (modifyData) => {
      // var copyFieldOption = [...fieldOption];

      axios.api_post("get_currentuser_profile", {}, (res) => {        
        const othersData = res.data.data;

        setForm({
          username: othersData?.username,
          age: othersData?.age,
          city: othersData?.city,
          state: othersData?.state,
          bio: othersData?.bio,
        });

        var fields = res.data.data.profile_fields;
        var preOptrions = [...modifyData];

        preOptrions.forEach(function(singleFld, findx) {
          // console.log(singleFld);
          fields.forEach(function (singleOpt, ufoindx) {
            var option_id = parseInt(singleOpt.option_id);
            var field_id = parseInt(singleOpt.field_id);
            
            let fieleIndex = -1;

            fieleIndex = _.findIndex(preOptrions, {
              id: singleOpt?.field_id,
            });

            singleFld.options.forEach(function (vl, i) {
              if (parseInt(vl.id) === parseInt(option_id)) {
                singleFld.options[i].isSelect = true;
              }
            });
            

          });
        });

        setfieldOption(preOptrions);
        setIsLoading(false);
      });
    },
    [fieldOption]
  );

  useEffect(() => {
    getFieldValue();
  }, [getFieldValue]);

  const selectedFieldsData = useCallback(
    (item, fieldName) => {
      console.log(item);
      var fieldOptions = [...fieldOption];
      if (fieldOptions?.length) {
        if (
          item.multi_option == 1
        ) {
          const modifyFieldOption = fieldOptions?.map((ele) => {
            if (ele?.id === item?.field_option_id) {
              return {
                ...ele,
                options:
                  ele?.options?.length &&
                  ele?.options?.map((eOption) => {
                    if (eOption?.id === item?.id) {
                      return {
                        ...eOption,
                        isSelect: !eOption?.isSelect,
                      };
                    } else {
                      return {
                        ...eOption,
                      };
                    }
                  }),
              };
            } else {
              return {
                ...ele,
              };
            }
          });
          setfieldOption(modifyFieldOption);
        } else {
          const modifyFieldOption = fieldOptions?.map((ele) => {
            if (ele?.id === item?.field_option_id) {
              return {
                ...ele,
                options:
                  ele?.options?.length &&
                  ele?.options?.map((eOption) => {
                    if (eOption?.id === item?.id) {
                      return {
                        ...eOption,
                        isSelect: true,
                      };
                    } else {
                      return {
                        ...eOption,
                        isSelect: false,
                      };
                    }
                  }),
              };
            } else {
              return {
                ...ele,
              };
            }
          });
          setfieldOption(modifyFieldOption);
        }
      }
    },
    [fieldOption]
  );

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setForm({
        ...form,
        [name]: value,
      });
    },
    [form]
  );

  const saveData = useCallback(() => {
    setIsLoading(true);
    if (fieldOption?.length) {
      let temp = [];
      fieldOption.forEach((element) => {
        const optionsData = element?.options;
        if (optionsData?.length) {
          optionsData.forEach((item) => {
            if (item?.isSelect) {
              temp.push(item?.id);
            }
          });
        }
      });

      let details = {
        username: form?.username,
        city: form?.city,
        age: form?.age,
        state: form?.state,
        bio: form?.bio,
        field_options: JSON.stringify(temp),
      };

      axios.api_post("save_user_profile_new", details, (res) => {
        setIsLoading(false);
        if(res.data.status) {
          Alert.showMessage("Success", "Profile Updated", "success","OK");
        } else {
          Alert.showMessage("OOPS", res.data.message, "error","OK");
        }
      });
    }
  }, [
    fieldOption,
    form.username,
    form.city,
    form.age,
    form.state,
    form.bio,
    getCurrentUser,
  ]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 col-sm-12">
            <section className="lamour-suggest new-block">
              <div className="container">
                <div className="row">
                  <div
                    className="block-stl2"
                    style={{ marginTop: "30px", padding: "3%" }}
                  >
                    <div className="inner-wrapper">
                      <div className="row">
                        {isLoading && <AmoureLoader />}
                        <div className="col-lg-6 col-md-6 col-xs-6 col-xl-6 col-sm-6">
                          <div class="form-group">
                            <label className="profile-label" for="username">
                              Username
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              autoComplete="off"
                              id="username"
                              name="username"
                              value={form?.username}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter username"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-6 col-xl-6 col-sm-6">
                          <div class="form-group">
                            <label className="profile-label" for="age">
                              Age
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="age"
                              autoComplete="off"
                              name="age"
                              value={form?.age}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter age"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-xs-6 col-xl-6 col-sm-6">
                          <div class="form-group">
                            <label className="profile-label" for="state">
                              State
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="state"
                              autoComplete="off"
                              value={form?.state}
                              name="state"
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter State"
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6 col-xs-6 col-xl-6 col-sm-6">
                          <div class="form-group">
                            <label className="profile-label" for="city">
                              City
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="city"
                              value={form?.city}
                              autoComplete="off"
                              name="city"
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter city"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-xl-12 col-sm-12">
                          <div class="form-group">
                            <label className="profile-label" for="bio">
                              Bio (Write something about yourself)
                            </label>
                            <textarea
                              id="bio"
                              autoComplete="off"
                              name="bio"
                              class="form-control"
                              onChange={(e) => handleChange(e)}
                              value={form?.bio}
                              rows="4"
                              cols="50"
                            ></textarea>
                          </div>
                        </div>
                        {fieldOption.length > 0 &&
                          fieldOption?.map((ele, idx) => {
                            // console.log("ele", ele);
                            return (
                              <div
                                className="col-lg-12 col-md-12 col-xs-12 col-xl-12 col-sm-12"
                                key={idx}
                              >
                                <label
                                  className="text-truncate profile-label"
                                >
                                  {ele?.field_name}
                                </label>

                                <div className="d-flex align-items-center flex-wrap">
                                  {ele?.options.length > 0 &&
                                    ele?.options?.map((item, iidx) => {
                                      // console.log("item", item);
                                      return (
                                        <div
                                          className={`rounded-pill mx-1 mb-2 ${
                                            item?.isSelect
                                              ? "profile-item-active-custome"
                                              : "profile-item-custome"
                                          }`} //
                                          key={iidx}
                                          onClick={() =>
                                            selectedFieldsData(
                                              item,
                                              ele?.field_name
                                            )
                                          }
                                        >
                                          {" "}
                                          <span
                                            className={`px-4 py-1 ${
                                              item?.isSelect
                                                ? "porfile-item-content-active-custome"
                                                : "porfile-item-content-custome"
                                            }  text-truncate`} //text-dark //porfile-item-content-active-custome
                                          >
                                            {item?.value}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        className="row"
                        style={{ marginTop: "40px", marginBottom: "20px" }}
                      >
                        <div className="col-lg-12">
                          <div className="text-center">
                            <div
                              className="c-btn btn1 custome-cursor"
                              onClick={() => saveData()}
                            >
                              Save
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
