import React, { Component } from 'react';
import '../App.css';

export default class SearchByMap extends Component {
  render(){
    return (
      <>
        <section className="page-info new-block">
          <div className="fixed-bg" style={{background: "url('images/page-info-bg.jpg')"}}></div>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <h2 className="clr1">Search Girls For Dating</h2>
                  <div className="clearfix"></div>
                  <ul className="list-unstyled">
                    <li><a href="#">Home</a></li>
                    <li><a href="#"> - </a></li>
                    <li><a href="#">Girls</a></li>
                  </ul>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by Name" />
                  <a href="#" className="sarch-member-btn"><i className="flaticon-search"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="map-section new-block map-block">
          <div id="map2"></div>
        </div>

        <div className="search-form om-m-now sbm new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <form>
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <label>I am a :</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Men</option>
                              <option value="">Girl</option>
                              <option value="">other</option>
                            </select>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <label>Looking For</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Choose Your Preferences</option>
                              <option value="">Men</option>
                              <option value="">Girl</option>
                              <option value="">other</option>
                            </select>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <label>Location</label>
                          <div className="form-group">
                            <select>
                              <option value="" selected>Choose Your Preferences</option>
                              <option value="">Usa</option>
                              <option value="">Uk</option>
                              <option value="">India</option>
                              <option value="">china</option>
                            </select>
                          </div>
                      </div>
                      <div className="col-lg-12 text-center">
                        <a href="#" className="c-btn btn1">Search</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="r-footer new-block pdtb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="newsleter">
                  <div className="title2">
                    <h2 className="clr1 fz35">Subscribe Newsleter</h2>
                    <div className="clearfix"></div>
                    <p className="fz20"> Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Email Address" />
                    <a href="#" className="subscribe-btn">Subscribe</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="footer-nav">
                  <ul className="list-unstyled">
                    <li><a href="home.html">Home</a></li>
                    <li><a href="search_girls.html">Girls</a></li>
                    <li><a href="search_girls.html">Boys</a></li>
                    <li><a href="#">Online Users</a></li>
                    <li><a href="online_search_country.html">Countries</a></li>
                    <li><a href="search_by_map.html">All World</a></li>
                    <li><a href="online_search_result.html">Filter Profile</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="contact_us.html">About us</a></li>
                    <li><a href="contact_us.html">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <span id="go_to_top" className="go-to-top"><i className="fa fa-long-arrow-up"></i></span>
          <div className="online-side-nav">
            <span className="nav-btn">
              <a href="#"><i className="flaticon-left-arrow"></i></a>
            </span>
            <div id="chat-sidebar">

              <div className="sidebar-user-box" id="100" >
                <img src="images/ou1.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Sumit Kumar Pradhan </span>
              </div> 

              <div className="sidebar-user-box" id="101" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Skptricks </span>
              </div> 

              <div className="sidebar-user-box" id="102" >
                <img src="images/ou3.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Amit Singh </span>
              </div> 

              <div className="sidebar-user-box" id="103" >
                <img src="images/ou4.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Neeraj Tiwari </span>
              </div> 

              <div className="sidebar-user-box" id="104"  >
                <img src="images/ou5.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Sorav Singh </span>
              </div> 

              <div className="sidebar-user-box" id="105" >
                <img src="images/ou6.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Lokesh Singh </span>
              </div> 

              <div className="sidebar-user-box" id="106" >
                <img src="images/ou7.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Tony </span>
              </div> 

              <div className="sidebar-user-box" id="107" >
                <img src="images/ou8.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Alex Robby </span>
              </div> 

              <div className="sidebar-user-box" id="108" >
                <img src="images/ou9.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette"></i>
                <span className="slider-username">Pragaya Mishra </span>
              </div> 

              <div className="sidebar-user-box" id="109" >
                <img src="images/ou2.jpg" alt=" " />
                <i className="flaticon-circular-shape-silhouette active"></i>
                <span className="slider-username">Abhishek Mishra </span>
              </div> 
             
            </div>
          </div>
      </>
    );
  }
}
