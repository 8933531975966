import React, { Component } from 'react';
import Header from './Components/Includes/Header';
import Footer from './Components/Includes/Footer';
import Chatbar from './Components/Includes/Chatbar';
import Router from './Router';
import Auth from './Auth';
import Socket from './utils/socket';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: new Socket("https://amoure.in:4004")
    };
  }

  render(){
    return (
      <div>
        <Header socket={this.state.socket}/>
        <Chatbar socket={this.state.socket}/>
        <Router/>
        <Footer/>
      </div>
    );
  }
}
