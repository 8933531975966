import React, { Component } from "react";
import "../App.css";
import { withRouter } from "react-router";
// import Carousel from 'react-bootstrap/Carousel'
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Auth from "../Auth";
import axios from "../utils/axios";
import Alert from "../utils/Alert";

// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

var THIS;
class AppLanding extends Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.state = {
      options: {
        male: false,
        female: false,
      },
      gender: "",
      genderStatus: false,
      lookingOptions: {
        men: false,
        women: false,
        both: false,
      },
      lookingGender: "",
      lookingGenderStatus: false,
      username: "",
      mobile: "",
      password: "",
      state: "",
      city: "",
      age: "",
    };

    THIS = this;

    if (Auth.isAuthenticated()) {
      THIS.props.history.replace("/home");
      window.location.reload();
    }

  }

  responseFacebook = (response) => {
    console.log(response);

    // this.callSocialLogin(response.id, response.email, "facebook", response.accessToken);
  };

  callSocialLogin = (id, email, provider, token) => {
    var _THIS = this;
    Auth.socialLoginCall(id, email, provider, token, (res) => {
      if (res.data.status) {
        localStorage.setItem("token", res.data.token);
        _THIS.props.history.replace("/home");
        window.location.reload();
      } else {
        Alert.showMessage("Amoure", "Login failed", "error", "OK");
      }
    });
  };

  responseGoogle = (response) => {
    var _THIS = this;

    const userObject = jwt_decode(response.credential);
    // console.log(userObject);

    this.callSocialLogin(
      userObject.sub,
      userObject.email,
      "google",
      response.credential
    );

  };

  handleInput(event) {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    return (
      <>
        <div
          id="login_container"
          className="vh-100 d-flex align-items-center justify-content-center"
        >
          <div className="container">
            <div className="row">
              <div
                id="login-middle"
                className="col-md-12 d-flex justify-content-center"
              >
                <div className="d-flex flex-column">
                  <label className="login_title">Amoure</label>
                  <span className='login_subtitle'>Extramarital dating site login</span>
                  <GoogleOAuthProvider clientId="204239561952-iuccvhcpspf762bpj8r9bv3r1bfmb593.apps.googleusercontent.com">
                  
                    <GoogleLogin
                    clientId="204239561952-iuccvhcpspf762bpj8r9bv3r1bfmb593.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <>
                        <a
                          className="btn btn-social btn-google"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <i className="fa fa-google"></i> Sign in with Google
                        </a>
                      </>
                    )}
                    buttonText="Login"
                    onSuccess={this.responseGoogle}
                    onFailure={(err) => {
                      console.log(err);
                    }}
                  />
                  </GoogleOAuthProvider>
                  
                  <FacebookLogin
                    appId="766426981376525"
                    autoLoad={false}
                    fields="name,email,picture"
                    render={(renderProps) => (
                      <button
                        className="btn btn-social btn-facebook"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <i className="fa fa-facebook"></i> Sign in with Facebook
                      </button>
                    )}
                    callback={this.responseFacebook}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="overlay-design">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">

            </div>
            <div className="col-lg-5 col-md-5">
              <div className="introducing-wrapper">
                <div className="introducing-block ">
                  <div className="inner-wrapper">
                    <GoogleLogin
                      clientId="204239561952-iuccvhcpspf762bpj8r9bv3r1bfmb593.apps.googleusercontent.com"
                      render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        </div>
      </>
    );
  }
}

export default withRouter(AppLanding);
