import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Front from "./Views/Front";
import Home from "./Views/Home";
import AdvanceSearch from "./Views/AdvanceSearch";
import MyPhotos from "./Views/MyPhotos";
import ProfileVisitor from "./Views/ProfileVisitor";
import AllDetails from "./Views/AllDetails";
import MyCrush from "./Views/MyCrush";
import MyFavourite from "./Views/MyFavourite";
import SearchBoys from "./Views/SearchBoys";
import SearchOnlineUsers from "./Views/SearchOnlineUsers";
import SearchCountryOnline from "./Views/SearchCountryOnline";
import SearchByMap from "./Views/SearchByMap";
import Blog from "./Views/Blog";
import About from "./Views/About";
import ContactUs from "./Views/ContactUs";
import TermsAndCondition from "./Views/TermsAndCondition";

import Details from "./Components/Details";
import Profile from "./Components/Profile";
import Pricing from "./Components/Pricing";
import Registration from "./Components/Registration";
// import Protected from './components/Protected';
import Auth from "./Auth";
import CreditShop from "./Views/CreditShop";
import AppLandingNew from "./Views/AppLandingNew";
import AppLanding from "./Views/AppLanding";
import CompleteProfileNew from "./Views/CompleteProfileNew";
import CompleteProfile from "./Views/CompleteProfile";

const Router = (props) => (
  <Switch>
    <Route exact path="/" component={AppLandingNew} />
    <Route exact path="/auth" component={AppLanding} />

    {/* <Route exact path="/sign_up" component={Registration} /> */}
    <PrivateRoute path="/home" component={Home} />
    {/* <Route path="/" component={CompleteProfileNew} /> */}
    <PrivateRoute path="/complete_profile" component={CompleteProfileNew} />
    {/*<PrivateRoute exact path='/SearchGirls' component={SearchGirls} />*/}
    <PrivateRoute exact path="/AdvanceSearch" component={AdvanceSearch} />
    <PrivateRoute exact path="/my_photos" component={MyPhotos} />
    <PrivateRoute exact path="/profile_visitor" component={ProfileVisitor} />
    <PrivateRoute exact path="/my_crush" component={MyCrush} />
    <PrivateRoute exact path="/my_favourite" component={MyFavourite} />
    <PrivateRoute exact path="/credit_shop" component={CreditShop} />
    <PrivateRoute exact path="/edit_profile" component={Profile} />
    {/*<PrivateRoute exact path='/registration' component={Registration} />*/}
    {/*<PrivateRoute exact path='/SearchOnlineUsers' component={SearchOnlineUsers} />*/}
    <PrivateRoute
      exact
      path="/SearchCountryOnline"
      component={SearchCountryOnline}
    />
    <PrivateRoute exact path="/SearchByMap" component={SearchByMap} />

    <PrivateRoute exact path="/profile_details/:id" component={AllDetails} />
    {/*<PrivateRoute exact path='/AllDetails' component={AllDetails} />*/}

    <Route exact path="/terms" component={TermsAndCondition} />
    <Route exact path="/About" component={About} />
    <Route exact path="/ContactUs" component={ContactUs} />
    {/*<Route path="/protected" component={Protected} />*/}
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

export default Router;
